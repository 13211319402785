.main-coll{
    width: 638px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px auto;
    background-color: #fff;
    padding:20px;
    border-radius: 6px;
    box-sizing: border-box;
}

.rating-title{
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.cta-review{
    margin-top: 24px;
}

.cta-review-title{
    color: #01195A;
    font-size: 16px;
    font-weight: 500;

}

.cta-tag{
    color:#01195A;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.cta-rev{
    color: #fff;
    background-color: var(--primary1);
    padding: 10px;
    border-radius: 3px;
    margin-top: 10px;
}

.tip{
    display: flex;
    align-items: center;
}

.tip-btn{
    background-color: var(--primary1);
    border: 1px solid var(--primary1);
    text-align: center;
    padding: 7px 20px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    cursor: pointer;
}
.tip-bton{
    text-align: center;
    padding: 7px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary1);
    display: flex;
    align-items: center;
}

.tip-bton span{
    background-color: var(--primary1);
    color:#fff;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    margin-right: 7px;
}

.abuse-bttn{
    cursor: pointer;
    width: 150px;
    text-align: center;
    padding: 7px;
    margin-left: 20px;
}

.abuse-bttn:hover{
    background-color:  #587FC0a7;
    border-radius: 50px;
}

.main-rev-dashboard{
    width: 625px;
     text-align: left;
}

.case{
    display: none;
}
.case-rev{
    display: none;
}

/* responsiveness starts here */

@media screen and (max-width:800px) {
    .main-rev-dashboard{
        width: 100%;
        margin: 0 ;
         text-align: left;
    }

  

    .rating-title{
        width: 100%;
        display: none;
    }
    .case-rev{
        text-align: left ;
        display: block;
    }
    .hr_line{
        display: none !important;
    }
    .main-coll{
        width: 100%;
        margin: 2px auto;
        border-radius: 0px;
    }

    .rev_dash_main_wrapper{
        width: 100%;
    }
}