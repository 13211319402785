.become-dealer-container {
	/* width: 960px; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 40px auto;
	/* background-color: #E5E5E5; */
}

.progressBarLine {
	margin: 20px 0 60px;
}

.main-dealer-coll {
	/* display: flex;
	justify-content: center; */
	/* border: 1px solid #E5E5E5; */
	
	background-color: #fff;
}

.progress-update-col {
	width: 242px;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
}

.progress-update-col label {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

.progress-update-col label input {
	margin-right: 0.5rem;
	/* -webkit-appearance: none; */
	width: 20px;
	height: 20px;
	border-radius: 50px;
}

.main-area-col {
	/* width: 980px; */
	/* background-color: #fff; */
	overflow: hidden;
	/* margin-top: -5.5rem; */
	border: 1px solid #E5E5E5;
	border-radius: 8px;
	width: 100%;
	max-width: 1190px;
}

.checkbox {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.checkbox__input {
	position: absolute;
	top: -100px;
	left: -100px;
}

.checkbox__inner {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-right: 10px;
	border: 1px solid #626262;
	background: transparent no-repeat center;
}

.checkbox__input:checked + .checkbox__inner {
	border-color: var(--primary1);
	background-color: var(--primary1);
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='10px' viewBox='0 0 14 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ios_modification' transform='translate(-27.000000, -191.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-Copy' transform='translate(0.000000, 164.000000)'%3E%3Cg id='ic-check-18px' transform='translate(25.000000, 23.000000)'%3E%3Cpolygon id='check' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	background-size: 14px 10px;
}

.mobile_form {
	display: none;
}

.desktop_form {
	display: block;
}

@media screen and (max-width: 800px) {
	.mobile_form {
		display: block;
	}

	.desktop_form {
		display: none;
	}

	.become-dealer-container {
		margin-top: 5rem;
		width: 420px;
	}

	.progress-update-col {
		/* display: none; */
		flex-direction: column;
		justify-content: center;
		width: 100%;
		padding: 0 1rem;
		margin: 0 auto;
	}

	.main-dealer-coll {
		width: 100%;
		flex-direction: column;
		/* justify-content: flex-start; */
	}
	.main-area-col {
		width: 100%;
		/* background-color: #fff; */
		overflow: hidden;
		margin-top: 0rem;
	}

	.become-dealer-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 40px auto;
	}
	.progressBarLine {
		margin: 10px 10px 40px;
		width: 95%;
		padding-bottom: 1rem;
		border-bottom: 0.5px solid #c4c4c4;
	}
	.welcom_bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border-bottom: 0.5px solid #c4c4c4;
	}
	.welcom_bar label {
		margin-right: -0.2rem;
		font-size: 11px;
	}
}
