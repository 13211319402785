.dp-main {
	overflow: hidden;
	border-radius: 50%;
	background-color: white;
	border: 1px solid var(--accent1);
}

.dp-inner {
	text-transform: uppercase;
	color: var(--accent1);
	margin: 0;
	font-weight: 700;
	object-fit: cover;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
