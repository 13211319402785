.main_head_wrapper {
	background-color: white;
	border-bottom: 1px solid #c4c4c480;
}

.switch-to-app,
.switch-top {
	display: none;
}

.header {
	height: 72px;
	/* max-width: 1280px; */
	display: flex;
	margin: auto;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	max-width: 1200px;
}

.topbarError {
	background-color: red;
	padding: 10px;
	text-align: center;
	color: white;
	font-weight: 500;
	font-size: 14px;
	/* width: 100%; */
}

.head_search_tab {
	display: none;
}

.logo > img {
	/* height: 100px; */
	width: 125px;
}

.toolbar {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.head_modal {
	width: 350px;
	background-color: #fff;
	/* padding: 20px; */
	margin-top: 65px;
	border-radius: 5px;
	outline: none;
	position: absolute;
	top: 1.3rem;
	right: 5rem;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.header-btn {
	height: 42px;
	min-width: 50px;
	border-radius: 5px;
	white-space: nowrap;
	display: grid;
	place-items: center;
	/* padding-left: 32px; */
	/* padding-right: 32px; */
	margin-left: 10px;
	color: var(--primary1);
	text-transform: capitalize;
	/* background: red; */
}

.header-btn:nth-last-of-type(1) {
	margin-left: 30px;
}

.header-btn:nth-last-of-type(2) {
	margin-left: 30px;
}

.accent1 {
	background-color: var(--accent1);
	color: white;
	font-weight: bold;
	min-width: 100px;
}

.dropdown {
	height: 300px;
	width: 300px;
	background-color: white;
	position: absolute;
	right: 100px;
	top: 90px;
	border-radius: 5px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

.user-profile-image {
	background-color: #c4c4c4;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	margin-right: 10px;
	overflow: hidden;
}

.user-profile-image img,
.user-modal-image img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.user-modal-image {
	background-color: #c4c4c4;
	border-radius: 50%;
	height: 60px;
	width: 60px;
	overflow: hidden;
}

.user_head_col {
	display: none;
}

.mobile_menu {
	display: none;
}

.menu_mobile-list-btn {
	background-color: var(--primary1);
	text-decoration: none;
	text-align: center;
	width: 90%;
	margin: 10px auto;
	list-style-type: none;
	color: #fff;
	padding: 9px;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 500;
}

.search-mod {
	display: none;
}

.searchDrop {
	display: none;
}

@media screen and (max-width: 800px) {
	.header {
		height: 72px;
		/* height: 10vh; */
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 1rem 0.5rem;
		background-color: white;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
		overflow-x: hidden;
		position: relative;
		z-index: 999;
		margin-bottom: 0.2rem;
		width: 100%;
	}
	.toolbar {
		display: none;
	}
	.logo {
		margin-left: 1rem;
	}
	.logo img {
		width: 100px;
	}

	.modaldrop {
		margin-right: 0;
	}

	.user_head_col {
		color: #01195a;
		font-size: 1.7rem;
		cursor: pointer;
		display: block;
	}

	.hamburger_head {
		display: flex;
		width: 60vw;
		justify-content: space-between;
		align-items: center;
		font-weight: 400;
	}
	.head_modal {
		width: 100%;
		/* max-width: 375px; */
		padding: 20px 10px;
		margin-top: 85px;
		border-radius: 0 0 15px 15px;
		outline: none;
		position: absolute;
		top: 1.4rem;
		right: 0px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	}
	.mobile_menu {
		display: block;
		position: fixed;
		z-index: 10;
		top: 115px;
		left: 0;
		background-color: #fff;
		width: 100%;
		/* max-width: 375px; */
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		margin: 0 auto;
		box-sizing: border-box;
		box-shadow: 1px 2px 9px #64646480;
		padding: 1rem 0.5rem;
		transition: 0.8s;
	}
	.mobile_menu .menu_mobile {
		list-style: none;
		padding: 0.8rem 1rem;
		font-size: 14px;
		font-weight: 500;
		color: #01195a;
		cursor: pointer;
	}
	.mobile_menu a {
		color: #01195a;
		text-decoration: none;
		transition: 0.8s;
	}
	.mobile_menu .menu_mobile:hover {
		color: var(--primary2);
	}
	.menu_mob_wrapper {
		width: 100vw;
		height: 100vh;
		background-color: #64646480;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
	}
	.head_search_tab {
		display: block;
		font-size: 1.5rem;
		color: #01195a;
		cursor: pointer;
		margin-right: -3rem;
	}

	.search-mod {
		display: block;
		border: none;
	}
	.searchDrop {
		background-color: #fff;
		width: 100%;
		position: absolute;
		top: 75px;
		left: 0;
		border: none;
		padding: 10px 0;
		border-radius: 0px 0px 10px 10px;
		display: block;
	}
	.search_title {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #01195a;
		border-bottom: 0.5px solid #c4c4c460;
		padding-bottom: 10px;
		padding-left: 40px;
	}

	.search_head_wrapper .search_head_item {
		list-style: none;
		padding: 15px 0;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: #01195a;
		cursor: pointer;
	}
	.main_head_icon {
		font-size: 1.5rem;
		color: #01195a;
	}
	.switch-to-app {
		display: block;
		width: 100%;
		height: 336px;
		background-color: var(--primary1);
	}

	.switch-top {
		width: 100%;
		height: 40px;
		background-color: var(--secondary1);
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
	}

	.switch-top button {
		background-color: #fff;
		border-radius: 6px;
		color: var(--secondary1);
		padding: 4px 15px;
	}

	.switch-top p {
		margin: 0;
		font-size: 8px;
		padding-top: 4px;
	}
	.switch-top h5 {
		font-size: 8px;
		margin: 0;
		font-weight: 600;
	}

	.switch-icon {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
	.switch-icon img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.switch-top-side {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.switch-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #fff;
		text-align: center;
		padding: 8px 16px;
	}

	.switch-close {
		align-self: flex-end;
		background-color: #5869c0;
		border-radius: 50px;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.switch-body .content22 {
		margin: 20px 0 20px;
		font-size: 18px;
		font-weight: 500;
	}

	.switch-body a {
		background-color: #fff;
		border-radius: 6px;
		color: var(--primary1);
		padding: 12px 50px;
		font-weight: 600;
		font-size: 14px;
		width: fit-content;
		margin: 0 auto;
		text-decoration: none;
	}
}
