.search-filter{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 97%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
}

.search-filter select {
    padding: 0.5rem 2rem ;
    margin-right: 2rem;
    border: none;
    font-size: 12px;
    outline: none;
    color: #7a7b7a;
    background: url("../../../Resources/Icons/selectarrow1.png") no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 120px;
}

.search-filter select:not(:first-child){
    border-left: 1px solid #e5e6e5;
}

.search-filter select option {
    color: #9a9b9a;
    
}

.mast-head-filter{
		box-shadow: 1px 1px 8px #cccccc70;
		height: 80px;
		display: flex;
        justify-content: center;
		align-items: center;
		padding: 1rem 0;

}
.search_box_line{
    display: flex;
		
		align-items: center;
		/* border-radius: 6;
		padding-left: 2rem; */
		justify-content: center;
        width: 100%;
        max-width: 1192px;
		
}

.paginatioon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.paginatioon p{
    padding: 1rem 1.4rem;
    font-size: 15px;
    font-weight: 500;
    color: #646464;
    cursor: pointer;
}

.paginatioon p:nth-child(3){
    color: var(--primary1);
}



.pagination {
    margin-top: 45px;
    margin-left: -40px;
    display: flex;
    justify-content: center;
    list-style: none;
    outline: none;
    font-size: 15px;
    font-weight: 500;
   }
    
   .pagination>.active>a {
    color: var(--primary1);
   }
    
   .pagination>li>a {
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
   }
    
   .pagination>li>a, .pagination>li>span {
    color: #000;
   }

.subcategory{
    padding: 0 0 0 10px;
    border-radius: 3px;
    width: 220px;
    
    margin-bottom: 4rem;
}

.subcategory li{
    list-style: none;
    
}

.selectdropDown{
    text-align: center;
    height: 42px;
    font-size: 16px;
    font-family: "montserrat";
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 1px solid var(--primary1);
    display: grid;
    place-items: center;
    padding: 0 0.5rem ;
    outline: none;
    -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
	background-size: 12px;
	background-position: calc(100% - 5px) center;
	background-position-y: 19px;
	background-repeat: no-repeat;
	background-color: #DBDEE7;
    
}
.drop_input {
    height: 42px;
    width: 100%;
    border: none;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.sel_mobile{
    display: none;
}

.sel_desktop{
    display: block;
    text-align: left;
}

.subcategory li label{
    display: flex;
    width: 200px;
    color: #000;
    font-size: 13px;
    font-weight: 300;
    justify-content: flex-start;
    cursor: pointer;
    align-items: center;
}

.subcategory li input{
    margin-right: 1rem;
    border: 1px solid #c4c4c4;
    width: 17px;
    border-radius: 3px;
    cursor: pointer;
  
}



.displayed_filter{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 720px;
    margin-left: 9.5rem;
    overflow-x: scroll;
}


.no-result{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
    height: 400px;
    font-size: 15px;
    margin: 0 auto;
}



.skeleton{
    width:200px;
}

.skels{
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    
}
.right-row2{
		padding-top: 20px;
		padding-left: 0;
		display: grid;
		grid-template-columns: repeat(auto-fill, 200px);
		justify-content: space-between;
		grid-gap: 20px;
		margin-bottom:4rem;
}

.main_query_wrapper{
		display: flex;
		justify-content: center;

}

.filter-right{
	max-width: 950px;
    width: 100%;
}

.main_filter_wrap{
		width: 100%;
		background: white;
}

.location-filter{
    width:150px; 
    background-position-x: 140px;
}
.year-filter{
    width:150px; 
    background-position-x: 140px;
}
.make-filter{
    width:150px; 
    background-position-x: 125px;
}
.model-filter{
    width:150px; 
    background-position-x: 135px;
}

.row1-filter{
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
}

.row-inside-filter{
    display: flex;
		justify-content: center;
		align-items: center;
		margin: 1rem 0;
		border-bottom: 0.5px solid #c4c4c460;
        width: 100%;
        max-width: 1190px;
}

.page-filter-total{
        margin: 1rem 0 1rem 0rem;
        /* width: 100px; */
}

.text1{
		font-size: 14px;
		width:100%;
        /* font-weight: bold; */
        margin: 0;
        letter-spacing: 0.5px;
	
}

.text2 {
    font-size: 12px;
    font-weight:300;
}

.right-row1{
		height: 117px;
		max-width: 100%;
		border-top: 7px solid var(--primary1);
		background-color: var(--secondary4);
		display: flex;
        flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;
		padding-left: 30px;
	

}

.filter-left{
		width: 250px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
        /* margin-left: 3rem; */
}

.filter-result-tab{
        width: 100px;
        font-size: 14px;
        border-bottom: 3px solid var(--primary1);
}
.filter-result-mobile{
    display: none;
        width: 100px;
        font-size: 14px;
        border-bottom: 3px solid var(--primary1);
}

.right_row4 {
    padding-top: 20px;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    justify-content: space-between;
    grid-gap: 20px;
    margin-bottom:4rem;
}

.skel-dummy{
    position: relative;
      width:100%;
}

.hidden-wrapper{
    display: none;
}

.mob_search_main_btn{
    display: none;
}

.desktop_search_main_btn{
    display: block;
}

@media screen and (min-width:1500px) {
    .search-filter{
        width: 100%;
    }
}

@media screen and (max-width:800px) {
    .no-result{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90vw;
        height: 300px;
        font-size: 18px;
    }
    .skeleton{
        width:156px;
    }
    
    .skel-dummy{
        position: relative;
          width:70vw;
    }

    .selectdropDown{
        background-color: #DBDEE7;
        text-align: center;
        height: 42px;
        font-size: 16px;
        font-family: "montserrat";
        border-radius: 6px;
        border: 1px solid var(--primary1);
        margin-bottom: 5px;
        
    }
    .drop_input {
        height: 42px;
        width: 100%;
        border: 1px solid #ccc;
        margin-bottom: 5px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .sel_desktop{
        display: none;
    }
    .sel_mobile{
        display: block;
        width: 100%;
    }
    .mob_search_main_btn{
        display: block;
        padding: 10px 55px;
        background-color: var(--primary1);
        color: #fff;
        border-radius: 6px;
        width: fit-content;
        font-size: 1rem;
        cursor: pointer;
    }
    
   
   
    .right_row4 {
        padding-top: 0px;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, 156px);
        justify-content: space-between;
        gap: 0px;
        margin-bottom:4rem;
    }
            .main_query_wrapper{
                display: flex;
            flex-direction: column;

            }
            .subcategory{
                display: none;
            }
            .subcategory p{
                display: none;
            }
            .filter-teg-sub{
                display: none ;
            }

            .page-filter-total{
                margin-right: 1.5rem !important;
            }

            .filter-right{
                max-width: 90%;
                margin-left: 1rem;
            }

            .main_filter_wrap{
                width: 100%;
                background: white;
                overflow-x: hidden;
        }
        .mast-head-filter{
            box-shadow: none;
            height: 80px;
            display: grid;
            align-items: center;
            padding: 1.5rem 0rem 0.2rem;
            width: 90%;
            margin: 0 auto;

        }   
        .page-filter-total{
            margin: 1rem 1.5rem 1rem 0rem;
    }
        .search-filter{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /* max-width: 100%; */
            height: 100%;
            background-color: #fff;
            border-radius: 5px;
        }

        .search-filter select {
            padding: 0.1rem 2rem ;
            margin-right: 0.5rem;
            border: none;
            flex: 1;
            font-size: 12px;
            outline: none;
            color: #7a7b7a;
            background: url("../../../Resources/Icons/selectarrow1.png") no-repeat right #fff;
            -webkit-appearance: none;
            background-position-x: 140px;
        }

        .search-filter select:not(:first-child){
            border-left: 1px solid #e5e6e5;
        }

        .search-filter select option {
            color: #9a9b9a;
            
        }
        .search_box_line{
            flex-direction: column;
            padding-left: 0;
            width: 100%;
            margin: 0 auto;
        }
        .location-filter{
            width:100%; 
            background-position-x: 90px;
        }
        .year-filter{
            width:100px; 
            background-position-x: 90px;
        }
        .make-filter{
            width:100px; 
            background-position-x: 75px;
        }
        .model-filter{
            width:100px; 
            background-position-x: 85px;
        }
        .row1-filter{
            display: none;
            justify-content: space-between;
            align-items: center;
            margin: 6rem 3rem 1rem 1rem;
            width: 90%;
            border-top: 0.5px solid #c4c4c460;
    }
    .displayed_filter{
        display: none;
        justify-content: flex-start;
        align-items: center;
        width: 240px;
        margin-left: 7rem;
        overflow-x: scroll;
    }
    .right-row2{
		padding-top: 20px;
		padding-left: 0;
		display: grid;
		grid-template-columns: repeat(auto-fill, 1fr);
		justify-content: space-between;
		grid-gap: 0px;
		margin-bottom:1rem;

}


.filter-result-tab{
    display: none;
}
.filter-result-mobile{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;
    color: var(--primary1);
   border: 0.5px solid #c4c4c460;
   padding: 10px 20px;
   border-radius: 5px;
   box-shadow: 1px 1px 8px #c4c4c460;
   margin-left: -2rem;
}
.filter-result-mobile span{
    margin-right: 0.2rem;
}
.hidden-filters{
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   width: 100%;
   flex-wrap: wrap;
   
}

.filter-left{
    margin-left: 3rem;
}

.hidden-wrapper{
    display: block;
    width: 100%;
    /* height: 1100px; */
    /* max-height: 1500px; */
    border-bottom: 0.5px solid #c4c4c460;
   margin-bottom: 2rem;
   box-shadow: 0 4px 2px -2px #c4c4c460;
   position: absolute;
   top: 0;
   left: 0;
   background-color: #fff;
   z-index: 30;
   overflow: hidden;
}
.filters-sort-close{
    border-bottom: 0.5px solid #c4c4c440;
}

.filters-sort-close p{
    color: #01195A;
    cursor: pointer;
    font-size: 2rem;
    margin: 0;
    padding: 20px 20px 10px;
    width: 10px;
}

.mobile-filters-sort{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 0.5px solid #c4c4c440;
}


.hidden-filters{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-flow: column wrap;
    width: 100%;
    /* height: 600px; */
    justify-content: flex-start;
    margin-right: 20px;
    padding: 20px;

}
.hidden-filters p{
    font-weight: 500;
    padding-bottom: 0;
    margin-bottom: 20px;
    padding-left: 0;
}
.hidden-filters .mobile-subcategory {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0;
    flex-flow: column wrap;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 800px;
    overflow-y: scroll;
    max-height: 800px;
}
.hidden-filters .mobile-subcategory li {
    width: 65%;
    list-style: none;
}
.hidden-filters .mobile-subcategory li label{
    display: flex;
    align-items: center;
    width: 70%;
    margin-right: 10px;

}
.hidden-filters .mobile-subcategory li input{
    margin-right: 10px;
}
.apply-btn{
    background-color: var(--primary1);
    color: #fff;
    padding: 10px 25px;
    cursor: pointer;
    border-radius: 5px;
    align-self: flex-end;
    justify-self: start;
    margin: 0.3rem auto 1.5rem;
    display: flex;
    align-items: center;
}
}

@media screen and (max-width:500px) {
        .main_filter_wrap{
            width: 100%;
            /* overflow-x: hidden; */
            margin: 0 auto;
            overflow-x: hidden !important;
        }
        .skel-dummy{
            position: relative;
              width:100%;
        }
        .skeleton{
            width:146px;
        }
        .right_row4 {
            grid-template-columns: repeat(auto-fill, 146px);
            gap: 10px;
        }
        
        .text1{
            font-size: 12px;
            width:100%;
            font-weight: 400;
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            margin: 20px 1rem !important;
            /* display: none; */
        }

        .text2 {
        font-size: 10px;
        font-weight:300px;
        /* margin-left: 1rem; */
        margin-bottom: 0;
        padding-bottom: 0;
        }

        .right-row1{
            display: none;
            height: 140px;
            max-width: 100%;
            border-top: 7px solid var(--primary1);
            background-color: var(--secondary4);
            /* display: flex; */
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 1rem auto;
            padding-left: 5px;
            padding-right: 10px;
        
    
    }
    .right-row2{
		padding-top: 20px;
		padding-left: 0px;
        padding-right: 0px;
		display: grid;
		grid-template-columns: repeat(auto-fill, 146px);
		justify-content: space-around;
		grid-gap: 20px;
		margin-bottom:1rem;
}
    .location-filter{
        width:70px; 
        background-position-x: 60px !important;
    }
    .year-filter{
        width:70px; 
        background-position-x: 50px !important;
    }
    .make-filter{
        width:70px; 
        background-position-x: 50px !important;
    }
    .model-filter{
        width:70px; 
        background-position-x: 50px !important;
    }
    .search-filter{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        height: 70%;
        background-color: #fff;
        border-radius: 5px;
        margin: 1rem 10px;
    }
    .row1-filter{
        display: none;
        justify-content: space-between;
        align-items: center;
        margin: 6rem 1rem 1rem 1rem;
        width: 90%;
}       


.search-filter select {
    padding: 0.1rem 12px ;
    margin-right: 0.2rem;
    border: none;
    font-size: 12px;
    outline: none;
    color: #7a7b7a;
    background: url("../../../Resources/Icons/selectarrow1.png") no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 140px;
}
.pagination {
    margin-top: 45px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    list-style: none;
    outline: none;
    font-size: 15px;
    font-weight: 500;
   }
}