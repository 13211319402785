.popboxlog {
	position: absolute;
	background-color: #00000080;
	z-index: 30;
	width: 100%;
	height: 150vh;
	padding-top: 60px;
	padding-bottom: 60px;
	top: 0;
	margin: 0;
	/* overflow-x: hidden; */
}

.login-tittle {
	font-size: 13px;
	font-family: "montserrat";
	/* width: 100%; */
	text-align: center;
	padding: 10px;
	font-weight: 500;
	margin-top: 14px;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
}

.popLogBg {
	position: absolute;
	top: -10px;
	right: 15px;
	cursor: pointer;
	font-weight: 400;
	font-size: 25px;
}

.login-col-wrap {
	background: white;
	/* height: 630px; */
	width: 435px;
	border-radius: 10px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
	align-items: center;
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 0;
	position: relative;
}

.control-pop {
	width: 263px;
}

.passs {
	border: none;
	width: 220px;
	margin: 0;
}

@media screen and (max-width: 800px) {
	.login-col-wrap {
		background: white;
		/* height: 630px; */
		width: 90vw;
		border-radius: 10px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
		align-items: center;
		margin: 0px auto;
		position: relative;
	}

	.popLogBg {
		top: -10px;
		right: 10px;
		cursor: pointer;
		font-weight: 400;
		font-size: 20px;
	}

	.login-tittle {
		font-size: 13px;
		width: 70%;
		padding: 10px 10px 5px;
		font-weight: 500;
		margin-top: 4px;
		margin-bottom: 10px;
	}

	.popboxlog {
		position: absolute;
		background-color: #00000080;
		z-index: 30;
		width: 100%;
		height: 150vh;
		padding-top: 30px;
		padding-bottom: 60px;
		margin: 0;
		/* overflow-x: hidden; */
	}
}
