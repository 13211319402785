.payment-success-container {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.success-page-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
}
.txt-success-pay-wrap {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  label {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
  span {
    color: #000;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 112.5% */
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 1000px) {
  .success-page-button-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 2rem;
  }
  .txt-success-pay-wrap {
    width: 90%;
  }
}
