.forgot {
    margin: 10px auto;
    padding: 30px;
    border-radius: 8px;
    width: 416px;
    justify-content: center;
    align-items: center;
}
.update-forgot{
    background: #fff;
    margin: 1rem auto;
    padding: 30px;
    border-radius: 8px;
    width: 416px;
    justify-content: center;
    align-items: center;
}
.forgot-input-reset-wrapper{
    width: 100%;
}
.forgot-bottom{
		display: flex;
		flex-direction: column;
        align-items: center;
}
.down-bottom{
    display: flex;
    flex-direction: row;
}
.update-wrapper {
    width: 100%;
    margin: 2px auto;
}

@media screen and (max-width:800px) {
    .forgot{
        width: 100%;
    }
    .forgot-bottom{
		flex-direction: column;
    }
    .down-bottom{
        display: flex;
    }
    .update-forgot{
        background: #fff;
        margin: 1rem auto;
        padding: 10px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
    }
    .update-wrapper {
        width: 100%;
        margin: 10px 0;
    }
    .alert-danger{
        width: 300px;
    }
}