.steps_main_wrapper {
	width: 100%;
	height: 100vh;
	background-color: #fff;
	/* position: fixed; */
}

.steps_indicator {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 95%;
	padding: 10px 10px;
	background-color: #fff;
}

.st_topBar {
	width: 95%;
	background-color: transparent;
	border-bottom: 0.2px solid #00000080;
	margin: 10px auto 10px;
	padding-top: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.st_branding {
	font-size: 15px;
	font-weight: 500;
	padding: 0;
}

.st_back {
	color: var(--primary2);
	cursor: pointer;
}

.st_indicator {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.indicator {
	border-top: 3px solid #adadad;
	width: 48%;
	text-align: center;
}

.st_btn {
	background-color: var(--primary2);
	width: 100%;
	padding: 7px 15px;
	margin: 15px 0 20px;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 700;
	color: #fff;
	border: none;
	cursor: pointer;
	text-align: center;
}

.details_step_form > div {
	width: 95%;
	display: flex;
	margin: 0px auto;
	justify-content: space-between;
	align-items: center;
	/* padding-right: 15px; */
}

.details_step_form select {
	padding: 7px 0;
	font-size: 14px;
	color: #000;
	border-radius: 3px;
	width: 100%;
	background-color: transparent;
}
.details_step_form input,
.details_step_form select {
	margin-top: 5px;
	border: 1px solid #c4c4c4;
	width: 100%;
	padding: 10px;
}

.details_step_form input::placeholder {
	color: #c4c4c4;
}

.steps_main_wrapper label {
	/* margin-right: 10px; */
	/* width: 50%; */
}

#checkbox_st {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

/* upload photo step begins here */
.st_shop_pix .st_notice {
	padding: 5px 20px;
	font-size: 11px;
}

.st_shop_pix .st_shop_image {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: center;
	width: 90%;
	margin: 0px auto 3px;
}

.st_ppix {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.st_shop_image .sh_photo_wrap {
	height: 130px;
	width: 130px;
	border: 1px solid #c4c4c4;
	border-radius: 6px;
	margin-right: 20px;
	/* overflow: hidden; */
	position: relative;
	/* margin-bottom: 1rem; */
}

.st_delete {
	position: absolute;
	right: -10px;
	top: -10px;
	background-color: red;
	padding: 6px 8px;
	border-radius: 50px;
	cursor: pointer;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
}

.sh_photo_wrap img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 6px;
}
.sh_photo_info {
	height: 130px;
	width: 130px;
	border: 1px solid #c4c4c4;
	border-radius: 6px;
	text-align: center;
	cursor: pointer;
	margin-right: 20px;
}

#info_mob-st {
	flex-direction: column;
}

#info_mob-st p {
	margin-top: 9px;
}

.sh_photo_info .num_uploaded {
	font-size: 11px;
}
.sh_photo_info .addPhoto {
	font-size: 13px;
	padding-top: 0;
}

.sh_photo_info .plus_st {
	color: var(--primary2);
	font-size: 45px;
	margin: 10px 0 0;
}

/* upload photo step stops here */

/* price steo begins here */

.st_price_wrap {
	width: 95%;
	margin: 20px auto;
}

/* Price step stops here */

/* error starts here */
.err_wrap {
	z-index: 997;
	background-color: #00000090;
	height: 100vh;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
}
.err {
	position: absolute;
	transform: translate(-50%, -50%);
	background-color: #fff;
	z-index: 999;
	top: 50%;
	left: 50%;
	width: 80%;
	height: 150px;
	border-radius: 6px;
	font-size: 16px;
	color: red;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 30px;
}

.err_btn {
	font-size: 25px;
	position: absolute;
	right: 5px;
	top: -20px;
	cursor: pointer;
}

#price {
	width: 95%;
	margin: 0px auto;
}

#negotiat {
	margin-top: 0rem;
}

.personal-details-shop-reg {
	color: var(--primary2);
	font-size: 18px;
	border-bottom: 1px solid var(--primary2);
}
.personal-details-shop-reg p {
	margin-bottom: 10px;
	font-weight: bold;
}
.personal-details-shop-title {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: center !important;
	margin-bottom: 1rem;
}
.personal-details-shop-title label {
	color: #000;
	text-align: center;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.5px;
}

.editAdform {
	padding: 0 !important;
	width: 100%;
}

.editAdform .textEditArea {
	width: 97%;
	margin-left: 15px;
}

.shop-deeds {
	display: flex;
	align-items: flex-start !important;
}

@media screen and (min-width: 801px) {
	.steps_indicator {
		width: 790px;
		transform: translate(-50%, -0%);
		bottom: 0;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		left: 50%;
		box-shadow: -2px -4px 8px #c4c4c490;
	}

	.mob-desktop-wrap {
		padding: 30px 0;
		display: flex;
		justify-content: center;
		align-items: stretch;
	}
	.st_shop_pix .st_notice {
		padding: 5px 0px;
		font-size: 11px;
	}

	.editAdform {
		padding: 30px 0 !important;
		width: 70% !important;
	}
	.editAdform .textEditArea {
		width: 100%;
		margin-left: 0px;
	}

	.textArea-label {
		width: 100% !important;
	}

	.st_back {
		font-weight: 500;
	}

	.steps_main_wrapper {
		width: 1200px;
		margin: 0px auto 30px;
		padding: 0px 0 30px;
		border-radius: 6px;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.err {
		width: 400px;
	}

	#price {
		width: 48%;
		margin: 0px 0px;
	}
	.st_btn {
		background-color: var(--primary2);
		width: 40%;
		/* margin-left: 20px; */
	}
	.st_shop_pix .st_shop_image {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		margin: 0px 0px 10px;
	}
	.details_step_form > div {
		width: 100%;
		padding-right: 0;
	}
	.details_parts_header {
		width: 100%;
		box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: 500;
		height: 58px;
		color: #000;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		margin-bottom: 1rem;
	}
}
