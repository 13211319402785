.find-job-wrapper {
  // width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
}
.inner-job-find-wrap {
  width: 1200px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}
.sections {
  // background-color: blue;
  width: 50%;
  padding-bottom: 1rem;
  &:last-child {
    margin-left: 2rem;
  }
}
.content-list {
  max-height: 720px;
  overflow-y: scroll;
  // background-color: aqua;
  // max-height: 520px;
  // overflow: scroll;
  // padding: 1rem;
}
.title-job-row {
  color: #000;
  font-size: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
}
.section-2-wrap {
  width: 100%;
  height: fit-content;
  // background-color: antiquewhite;
}
.sub-title {
  color: #222;
  font-size: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  margin-top: 5px;
}

.small-text-bellow-call {
  color: #222;
  font-size: 11px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
.call-now {
  color: #fff;
  font-size: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  // margin-left: 1rem;
}
.profile-area {
  width: 100%;
  height: fit-content;
  margin-top: 1rem;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #c4c4c4;
  }
}
.text-wrap {
  display: flex;
  width: 300px;
  // background-color: pink;
  flex-direction: column;
  margin-left: 1rem;
}
.title-txt-prod {
  color: #222;
  font-size: 13px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin-bottom: 5px;
}
.body-wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
}
.small-description {
  color: #222;
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  margin-top: 10px;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 16px;
}
.small-txt-body {
  text-align: left;
  color: #000;
  font-size: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 1000px) {
  .inner-job-find-wrap {
    flex-direction: column;
    width: 100%;
  }
  .sections {
    padding-left: 5px;
    padding-right: 5px;
    &:first-child {
      width: 100%;
    }
    &:last-child {
      width: 100%;
      margin-left: 0rem;
      height: 100%;
      padding: 1rem;
    }
  }
  .content-list {
    max-height: fit-content;
  }
}
