.dealer-address{
    border: 0.5px solid #c4c4c460;
    border-radius: 3px;
    padding: 1rem;
    width: 70%;
}

.dealer-panel-main{
    width: 350px;
    min-width: 242px !important;
}

.dealer-details{
    width: 800px !important;
}

.user-dealer{
    width: 100%;
}

.dealer-pane{
    height: 370px !important;
    margin-bottom: 0 !important;
}

.dish-div{
    border-bottom: 0.5px solid #c4c4c4;
    width: 100%;
    margin: 0.5rem 0;
}

.dealer-btn{
    margin: 1rem 0 2rem;
    width: 60%;
}

.deal-img{
    width: 80px;
    height: 80px;
    margin-bottom: 0.5rem;
}

.wrap-dealer{
    background-color: #ededed;

}


@media screen and (max-width:800px) {
    .details-top{
        display: none !important;
    }

    .dealer-panel-main{
        width: 90%;
        min-width: 242px !important;
        margin: 0 auto;
    }
    
    .dealer-details{
        margin: 0 auto !important;
        width: 90% !important;
        margin-top: 30px !important;
        padding: 20px 0;
    }
   
}