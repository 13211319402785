#job-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  .job-containner-inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    padding: 10rem;
    align-items: center;
    justify-content: center;

    // Item
    .inner-wrap-item {
      position: relative;
      height: 341px;

      width: 607px;
      flex-direction: column;
      // height: 341px;
      display: flex;
      border-radius: 8px;
      overflow: hidden;
      align-items: center;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
      span {
        color: #587fc0;
        font-size: 18px;
        text-align: center;
        font-family: "Montserrat";
        font-weight: 500;
        margin-top: 10px;
        line-height: 22px;
        letter-spacing: 0.5px;
      }
      label {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-family: "Montserrat";
        margin-top: 10px;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
      .card-section {
        width: 100%;
        // background-color: #587FC0;
        height: fit-content;
        position: absolute;
        bottom: 0px;
        padding-bottom: 2rem;
        span {
          color: #000;
          font-size: 15px;
          font-family: "Montserrat";
          line-height: 17px;
          letter-spacing: 0.5px;
          margin-left: 35px;
        }
        .row-card-section {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding-left: 3rem;
          padding-right: 3rem;
          margin-top: 1rem;
          .card {
            width: 129px;
            height: 137px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 6px;
            border: 1px solid #000;
            span {
              color: #000;
              text-align: center;
              font-size: 14px;
              // background-color: #587FC0;
              margin-right: 3rem;
              align-self: center;
              font-family: "Montserrat";
              font-weight: 600;
              width: 100%;
              line-height: 15px;
              letter-spacing: 0.5px;
            }

            // background-color: antiquewhite;
          }
        }
      }
    }
  }
  .txt-job {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.5px;
  }

  // ====================Mobile=====================
  .mobile-wrapper {
    width: 100%;
    height: 100%;
    // background-color: aqua;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    span {
      color: #587fc0;
      font-size: 18px;
      text-align: center;
      font-family: "Montserrat";
      font-weight: 500;
      margin-top: 10px;
      line-height: 22px;
      letter-spacing: 0.5px;
    }
    .mobile-wrapper-card-section {
      width: 100%;
      display: inline-block;
      margin-top: 5rem;
      span {
        color: #000;
        font-size: 15px;
        font-family: "Montserrat";
        line-height: 17px;
        letter-spacing: 0.5px;
        margin-bottom: 2rem;
      }
      .column-container {
        margin-top: 1rem;
        // background-color: pink;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex-direction: column;
        .card {
          height: 200px;
          margin-top: 2rem;
          width: 100%;
          cursor: pointer;
          border-radius: 6px;
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: rgba(255, 255, 255, 0.7);
          span {
            font-weight: bold;
            cursor: pointer;
            font-size: 20px;
            color: black;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
