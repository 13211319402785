.post_wrapper {
	margin: 10px auto;
	background-color: #fff;
	/* width: 810px; */
	border-radius: 7px;
	padding: 12px 0px;
	background-color: #fff;
}

.edit-wrapper {
	margin: 40px auto;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	max-width: 1200px;
	border-radius: 7px;
	padding: 32px 25px;
	display: flex;
	justify-content: center;
}

.form_title {
	color: var(--primary2);
	font-size: 18px;
	line-height: 20px;
	border-bottom: 0.5px solid #fbb500;
	margin: 16px 1rem 9px;
	width: 100%;
	margin: 0 auto;
	font-weight: bold;
}

.product-edit-tag {
	width: 50%;
	text-align: center;
	margin: 15px auto;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.5px;
}

.ad-form input::placeholder {
	color: #949496;
}

#price {
	width: 100%;
}

#price p {
	width: 10%;
	text-align: center;
	color: black;
	padding: 10px;
	font-size: 1.1rem;
	font-weight: 500;
}

#price .price-col {
	display: flex;
	align-items: center;
	border: 1px solid #dbdee7;
	border-radius: 5px;
	background-color: #fbb500;
	height: 42px;
	margin-top: 0.5rem;
	/* margin-bottom: 2rem; */
}

#price input {
	/* width: 350px; */
	border: none;
	padding: 10px;
	margin: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	/* background-color: rgb(253, 209, 209); */
}

.ad-form {
	/* padding-left: 1rem;
	padding-right: 3rem; */
	width: 70%;
}

.edit-wrapper .ad-form {
	margin: 0 auto;
	width: 100%;
}

.edit-wrapper .form_title {
	width: 100%;
}

.adFormNew {
	width: 60% !important;
	margin-top: 30px;
}

.ad-form input:focus,
.ad-form select:focus,
.ad-form textarea:focus {
	box-shadow: 1px 1px 4px 2px rgba(181, 180, 180, 0.32);
}

.ad-form label {
	display: flex;
	flex-direction: column;
	margin: 1rem 0rem;
	width: 48%;
	font-size: 14px;
	position: relative;
	/* background-color: red; */
}

#relative-keyword {
	width: 100%;
}

.ad-form div {
	/* width: 100%; */
	display: flex;
	justify-content: space-between;
}

.ad-form input,
.ad-form textarea,
.ad-form select {
	margin-top: 10px;
	border-radius: 4px;
	border: 1px solid #dbdee7;
	outline: none;
	padding: 10px;
	color: #000;
	font-size: 14px;
	width: 100%;
	font-family: "montserrat";
}

.ad-form select {
	background: url("../../../Resources/Icons/selectarrow1.png") no-repeat right #fff;
	-webkit-appearance: none;
	background-position-x: 375px;
	/* width: 100%; */
}

.ad-form input {
	/* padding:17px 10; */
	height: 40px;
	color: #222;
}

.ad-form textarea {
	width: 100%;
}

.warning {
	color: red;
	font-weight: 300;
	font-size: 11px;
	position: absolute;
	top: 72px;
	border-radius: 5px;
	left: 0px;
	/* background-color: rgb(252, 234, 234); */
}

.become-a-seller label div {
	flex-direction: column;
}

.become-a-seller .warning {
	color: red;
	font-weight: 300;
	font-size: 11px;
	position: relative;
	top: 10px;
	border-radius: 5px;
	left: 0px;
	/* background-color: rgb(252, 234, 234); */
}

.input-info {
	font-size: 9px;
	line-height: 12px;
	margin-top: 4px;
}

.negotiate {
	display: flex;
	justify-content: flex-start !important;
	align-items: center;
	width: 40%;
	margin-top: -25px;
	/* margin-left: 15px; */
	margin-bottom: 1rem;
}

.negotiate p {
	padding-top: 5px;
	color: #1976d2;
}

.negotiate input {
	height: 40px;
	width: 20px;
}

.negotiable {
	margin-right: 5px;
	border: 1px solid #1976d2;
}

/* remove arrows from number field starts here */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* remove arrows from number field ends here */

.image-up {
	width: 18px;
	height: 37px;
	font-size: 30px;
	font-weight: 400;
	color: #949496;
	margin: 0;
	cursor: pointer;
	border: 0.5px solid #738ead;
	padding: 0 0.5rem;
}

.image-item {
	display: flex;
	flex-direction: row;
	margin: 0 1rem 1rem 0.2rem;
	padding: 5px;
	border: 0.5px solid #949496;
	/* height: 450px; */
	/* overflow-y:auto; */
}

.image-list-col {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* flex-direction: column; */
	margin-left: 0.2rem;
}

.img-upload p {
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	padding-bottom: 0px;
}

/* .image-item__btn-wrapper p:not(:first-child){
    color:red;
} */

.reload {
	position: absolute;
	bottom: -10px;
	right: 0px;
	color: #fff;
}

.reloadbin {
	position: absolute;
	top: 0px;
	right: 0px;
	color: #000;
	background-color: #fff;
	border-radius: 50%;
	padding: 0px 4px 1px;
	font-size: 9px;
}

.image-item__btn-wrapper p {
	cursor: pointer;
	margin: 0 0 10px 5px;
}

.image-item__btn-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
}

.cat option {
	padding: 2px 10px;
	border: 1px solid #979997;

	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;

	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
}

.post_btn {
	background-color: var(--primary2);
	text-transform: none;
	font-weight: bolder;
	font-size: 18px;
	border: 1px solid;
	padding-left: 150px;
	padding-right: 150px;
	font-family: "montserrat";
	margin-top: 1rem;
	margin-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 5px;
	margin-bottom: 3rem;
}

.post_tag {
	width: 50%;
	text-align: center;
	margin: 35px auto 10px;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.5px;
}

@media screen and (max-width: 800px) {
	.ad-form div {
		display: flex;
		/* width: 100%; */
	}
	.edit-wrapper {
		margin: 40px auto;
		background-color: #fff;
		width: 100%;
		border-radius: 7px;
		padding: 32px 0px;
		overflow-x: hidden;
	}
	.ad-form input,
	.ad-form select {
		width: 100%;
	}
	.ad-form {
		padding-left: 10px;
		padding-right: 10px;
		width: 95%;
	}

	.adFormNew {
		width: 100% !important;
		margin-top: 10px;
	}

	.ad-form label {
		width: 100%;
	}

	.product-edit-tag {
		width: 90%;
		text-align: center;
		margin: 15px auto;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.5px;
	}
	.form_title {
		width: 100%;
		margin: 0 auto;
		font-weight: 600;
	}

	.post_wrapper {
		margin: 12px auto;
		background-color: #fff;
		width: 100%;
		border-radius: 7px;
		padding: 32px 0px;
		overflow-x: hidden;
	}
	#price input {
		width: 100%;
	}
	.ad-form select {
		background-position-x: 160px;
	}
	.post_btn {
		width: 90%;
		padding: 10px 0;
		margin-top: 3rem;
	}
	.ad-form label {
		margin: 1rem 5px;
		width: 100%;
	}

	.negotiable {
		width: auto !important;
	}
	.post_tag {
		width: 80%;
		text-align: center;
		margin: 10px auto;
		font-size: 13px;
	}

	.ad .image-item {
		display: flex;
		flex-direction: column;
		margin: 0 0rem 0rem 0rem;
		padding: 1px;
		border: 0.5px solid #949496;
		width: 40px;
	}
	/* .image-item p{
            font-size: 7px;
            margin: 0;
            margin-bottom: 2px;
        } */

	.image-item img {
		width: 40px;
		height: 40px !important;
		object-fit: cover;
		margin: 0 !important;
	}
	.image-list-col {
		grid-template-columns: repeat(3, 1fr);
	}

	.image-item__btn-wrapper {
		display: flex;
		flex-direction: row;
		background-color: red;
	}

	.reload {
		position: absolute;
		bottom: -15px;
		right: 0px;
		color: #fff;
		font-size: 14px;
	}

	.reloadbin {
		position: absolute;
		top: -40px;
		right: 0px;
		color: #000;
		background-color: #fff;
		border-radius: 50%;
		padding: 0px 4px 1px;
		font-size: 10px;
		font-weight: 500;
	}
	.ad-form textarea {
		width: 100%;
	}
}
@media screen and (max-width: 380px) {
	.ad-form select {
		background-position-x: 140px;
	}
}
