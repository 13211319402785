.confirm-job-wrapper{
  width: 100%;
  height: 100%;
justify-content: center;
display: flex;
// background-color: maroon;
padding-top: 2rem;
}
.confirm-inner-wrapper{
width: 638px;;
  height: 500px;
  display: flex;
  flex-direction: row;
  background-color: white;
}
.row-item-confirm-wrap{
  width: 50%;
  &:first-child {
      // background-color: green;
      margin-right: 8px;
    }
  &:last-child{
    // background-color: pink;
    margin-left: 8px;
  }
}

.left-title-txt{
 color: #000;
    font-size: 18px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.5px;
}
.left-card{
  display: flex;
  flex-direction: row;
 
  align-items: center;
  margin-top: 1rem;
}
.left-detail-section{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 1rem;
}
.title-left-card{
  color: #222;
    font-size: 13px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-top: 5px;
}
.card-small-txt{
  color: #222;
    font-size: 11px;
    margin-top: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}
.wrapper-payment-method-req{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // background-color: wheat;
  margin-top: 1rem;
  margin-bottom: 1rem;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    height: 50px;
}
.pay-method-txt{
  color: #222;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}
.add-txt{
  color: #587FC0;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}
.right-row-txt-container{
  width: 100%;
  display: flex;
  margin-top: 2rem;
  border-bottom: 1px solid #C4C4C4;
  flex-direction: column;
  padding-bottom: 1rem;
}
.row-text{
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
    margin-top: 10px;
}
.row-txt{
   color: #222;
    font-size: 13px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    line-height: 14px;

}
.row-total-txt{
  color: #222;
    font-size: 13px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    // margin-top: 8px;
}
.confirm-note-txt-wrap{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

}
.confirm-check-box{
  width: 16px;
    height: 16px;
    border-radius: 4px;
      border: 0.5px solid #000;
      background: #FFF;
}
.note-txt{
  color: #222;
    font-size: 12px;
    margin-left: 1rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.terms-color{
    font-size: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #587FC0;
}

@media screen and (max-width:1000px) {
  .confirm-inner-wrapper {
    width: 100%;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .row-item-confirm-wrap {
      width: 100%;
  
      &:first-child {
        // background-color: green;
        margin-right: 0px;
      }
  
      &:last-child {
        // background-color: pink;
        margin-left: 0px;
        margin-top: 1rem;
      }
    }
        .right-row-txt-container {
          margin-top: 0rem;
        }
}