.up_wrapper{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.upload-container{
	margin-top: 64px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    display: flex;
}



.image-upload-photo {
	background-color: #ddd;
	width: 120px;
	height: 18px;
	text-align: center;
	padding: 5px;
	color: #000;
	cursor: pointer;
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	/* border: 0.3px solid grey; */
}

.upload_user_icon_main {
	font-size: 14rem;
	color: #c4c4c4;
}

.upload_btn_profile {
	display: flex;
	align-items: center !important;
	justify-content: center !important;
	margin: 1rem auto !important;
	background-color: var(--primary1) !important;
	color: #fff !important;
	font-weight: 700 !important;
}

.finished-upload {
	position: relative;
	z-index: 60;
}

.upload-col {
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
}

.image-list-coll {
	position: relative;
	width: 100px;
}

.image-item__btn-wrapper p {
	cursor: pointer;
	margin: 0 0 10px 5px;
}

.image-item__bton-wrapper {
	display: flex;
	flex-direction: row-reverse;
	position: absolute;
	bottom: 5px;
	right: 0px;
	color: #fff;
	font-size: 1rem;
}

.image-items {
	display: flex;
	flex-direction: row;
	margin: -2rem 1rem 1rem 0.2rem;
	padding: 5px;
	border: 0.5px solid #949496;
	width: 100px;
	height: 100px;
	border: none;
}
.image-items p {
	margin-right: 0.5rem;
	cursor: pointer;
}

.image-items img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.bin-btn {
	position: absolute;
	top: -4rem;
	right: 0px;
}

.skip-col {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.skip-col .dis-btn {
	background-color: var(--primary1);
	padding: 10px 15px;
	width: 416px;
	color: #fff;
	font-weight: 700;
	font-size: 18px;
	border-radius: 6px;
	margin-right: 1rem;
	/* cursor: not-allowed; */
}

@media screen and (max-width: 768px) {
	.upload-main-col{
		width: 100%;
	}
	.skip-col .dis-btn {
		width: 100%;
	}

	.skip-col{
		width: 100%;
	}

	.choose{
		width: 100% !important;
	}
	.upload-container{
		width: 90%;
		box-sizing: border-box;
	}
	.inner-upload{
		width: 90%;
	}
}
	

