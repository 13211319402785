.big-pr-photo-wrapper {
	overflow: hidden;
}

.big-pr-photo {
	/* transform: scale(1.4); */
	transition: 3s;
}

/* .big-pr-photo:hover{
    transform: scale(2.5);
} */

.thumbnails {
	overflow: hidden;
	cursor: pointer;
}

.thumbnails img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.share-btn {
	margin: 10px 1rem;
	background-color: #1976d3;
	color: #fff;
	padding: 5px 10px;
	border: 1px solid #000;
	border-radius: 5px;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.mobileShareBar{
	display: none;
}

.social-share-btn {
	position: absolute;
	width: 230px !important;
	bottom: -179px;
    left: -115px;
	z-index: 66;
	background-color: #fff;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px 0;
	border: 1px solid #C4C4C4;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	overflow: hidden;
	/* padding-top: 4px; */
}

.social-share-btn a {
	background-color: red !important;
}

.each-share-button {
	/* margin-left: 0.5rem; */
	width: 100%;
}

.abuse-btn {
	border: 1px solid #c4c4c4;
	color: #646464;
	padding: 5px 18px;
	border-radius: 4px;
	margin: 12px auto 16px;
}

.return-btn {
	background-color: var(--primary1);
	font-weight: 700;
	font-size: 18px;
	padding: 15px 75px;
	border: 0.5px solid #000000;
	box-sizing: border-box;
	border-radius: 6px;
}

.abuse-pop {
	width: 400px;
	height: 200px;
	background-color: #fff;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	border: none;
	border-radius: 8px;
	text-align: center;
	padding: 15px 20px;
}

.abuse-pop button {
	background-color: var(--primary1);
	border: 0.5px solid #646464;
	color: #000;
	font-size: 16px;
	padding: 10px 35px;
	border-radius: 6px;
	margin-top: 1rem;
}

.abuse-pop h3 {
	margin-bottom: 0.5rem;
}

.abuse-pop .abuse-pop-close {
	position: absolute;
	right: 15px;
	top: 10px;
	font-weight: 400;
	cursor: pointer;
}

.share-input-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0.5px solid #c4c4c4;
	width: 349px;
	height: 40px;
	margin-top: 1rem;
	border-radius: 4px;
}

.share-input-btn input {
	border: none;
	width: 100%;
	color: #646464;
}

.share-input-btn input::placeholder {
	color: #c4c4c4;
	width: 100%;
}

.share-input-btn button {
	background-color: var(--primary1);
	color: #fff;
	padding: 12px 25px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	height: 100%;
	width: 85px;
	transform: none;
}

.click-to-view {
	background-color: var(--primary1);
	color: #fff;
	width: 238px;
	text-align: center;
	/* padding: 10px 5px; */
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	height: 42px;
}
.tab-to-view-share {
	border-bottom: 1px solid #c4c4c4;
	color: #000;
	width: 100%;
	padding: 0 20px;
	text-align: center;
	font-size: 13px;
	/* font-weight: 500; */
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 3px;
	margin: 0px 0 0px;
	padding: 7px 30px !important;
	text-decoration: none;
}



.link-to-store {
	text-decoration: none;
	color: var(--primary1);
}

.link-to-store:hover {
	color: var(--primary2);
}

.address-shop-col {
	width: 237px;
	padding: 15px;
	box-sizing: border-box;
	font-size: 12px;
	text-transform: capitalize;
	color: #000000;
	border: 1px solid #c4c4c4;
	border-radius: 3px;
	line-height: 18px;
	letter-spacing: 0.5px;
	margin: 0;
	font-weight: 400;
}

.shop-img-col {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 229px;
}

.joined-col {
	background: #dbdee7;
	border-radius: 6px;
	padding: 3px 10px;
	width: fit-content;
	/* text-align: center; */
	margin: 0 0 ;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	display: none;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
	display: none;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	display: none;
}


.share-icon-btn{
	position: relative;
}

.like-parts{
	display: flex;
	align-items: center;
	margin: 0 0 17px;

}

.like-parts span{
	margin-left: 10px;
	cursor: pointer;
}
/* ====== MEDIAQUERY ======= */

@media screen and (max-width: 800px) {
	.share-input-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 0.5px solid #c4c4c4;
		width: 96%;
		height: 40px;
		margin-top: 1rem;
		border-radius: 4px;
	}

	.social-share-btn {
		display: flex;
		align-items: center;
		position: fixed;
		width: 100vw !important;
		bottom: -10px !important;
		left: 0;
		z-index: 999;
		background-color: #fff;
	}

	.mobileShareBar{
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		padding: 0px 16px;
		width: 100vw;
		border-bottom: 1px solid #c4c4c4;
	}

	/* .mySwiper{
		padding-left: 10px;
	} */

	.thumbnails {
		margin-right: 10px;
	}
	.swiper-button-next:after,
	.swiper-container-rtl .swiper-button-prev:after {
		display: block;
		content: "next";
		font-size: 1rem !important;
		font-weight: 700 !important;
		background-color: #fff !important;
		border-radius: 50% !important;
		box-shadow: 1px 1px 8px #c4c4c4;
		padding: 10px 13px !important;
		cursor: pointer;
	}
	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		top: 290px !important;
	}

	.swiper-container-pointer-events {
		padding-bottom: 1rem;
	}

	.big-pr-photo-wrapper {
		border-radius: 8px;
	}
}
