.displayed_filt p{
    color: #000;
    padding: 5px 15px ;
    margin-right: 1rem;
    border-radius: 50px;
    font-weight: 300;
    border: 1px solid #646464;
    font-size: 14px;
    width: fit-content;
    /* min-width: fit-content !important; */
    box-sizing: border-box;
    height: 32px !important;
    overflow: hidden;
}


.displayed_filt p span {
    font-weight: bolder;
    cursor: pointer;
    font-size: 15px;
    color: #000;
    padding-top: 1rem;
}

.pre_filter{
    margin: 0; 
    font-family: "montserrat";
}


