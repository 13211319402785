.display-image-pr{
    display: flex;
    align-items: flex-start;
}

.rate_product_coll{
		width: 486px;
		background-color: white;
		padding: 20px;
		margin-top: 40px
	
}

.close_prod_rev_btn{
    position: absolute;
    top: -10px; 
    right: 0px;
    cursor:pointer;
    text-align:center;
    color:#000; 
    background-color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.display-image-pr .pr-image{
    background-color: #c4c4c4;
    width:84px;
    height: 84px;
    border-radius: 4px;
    margin-right: 1rem;
    margin-bottom: -10px;
    border: 2px solid #c4c4c4;
}

.pr-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
}

.display-image-pr .details-name{
    margin-top: 0;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #222;
}

.display-image-pr .details-numb{
    color: #000;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.display-image-pr .details-price{
    color: var(--primary1);
    font-weight: 600;
    font-size: 13px;
}

.upload-del-btn{
    position: absolute;
    top: 0;
    right: 0;
}

.review-error{
    color: red;
    
}

@media screen and (max-width:600px) {
    .rate_product_coll{
		width: 100%;
		background-color: white;
		padding: 40px 20px 20px;
		margin-top: 0px
	
}
.close_prod_rev_btn{
    position: absolute;
    top: -53px; 
    right: -3px;
    cursor:pointer;
    text-align:center;
    color:#000; 
    background-color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}
}