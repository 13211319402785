.calendar {
	border: none;
}

.calendar-tile {
	background: var(--primary1);
}

#abuse-btn:hover {
	background-color: var(--accent_opacity) !important;
}

.mech-like{
	width: 100px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	padding-left: 16px;
	margin-right: 30px;
	/* position: absolute;
	bottom: 0;
	left: 0; */
}

.like-contain{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: 1px solid rgb(213, 213, 213);
	margin-bottom: 20px;
}

.mech-like .likeBtn{
	background-color: #fff;
	border-radius: 50px;
	padding: 4px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
	padding: 10px;
	border: 1px solid #c4c4c469;
	
	
}
.mech-like .share{
	background-color: #fff;
	border-radius: 50px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 10px;
	border: 1px solid #c4c4c469;
}

.part-saved-wrap{
	display: flex;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	padding: 16px 15px;
	margin: 16px 0;
	height: 138px;
}
.mech-saved-wrap{
	display: flex;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	padding: 16px 15px;
	margin: 16px 0;
	height: 135px;
}

.parts-image-saved{
	min-width: 100px;
	width: 100px;
	height: 60px;
	margin-right: 16px;
}
.mech-image-saved{
	width: 80px;
	height: 80px;
	margin-right: 16px;
	background-color: var(--primary1);
	border-radius: 4px;
}

.parts-image-saved img, .mech-image-saved img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.mech-address{
	width: 100%;
}

.like-saved{
	font-size: 8px;
	font-weight: 500;
	color: #646464;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
}

.part-saved-wrap span,
.mech-saved-wrap span{
	margin-right: 7px;
}

.like-title{
	font-size: 12px;
	color: #587FC0;
	padding: 0;
	margin: 8px 0;
	
}

.like-title:hover{
	transition: .8s;
	color: rgb(236, 158, 14);
}
.like-mech-title{
	font-size: 12px;
	color: #01195A;
	font-weight: 500;
	padding: 0;
	margin: 8px 0;
}
.like-code{
	font-size: 10px;
	color: #000000;
	padding: 0;
	margin: 0;
}

.like-address{
	font-size: 10px;
	font-size: 300;
	padding: 0;
	margin: 7px 0;
}

.like-price{
	color: #587FC0;
	font-size: 14px;
	font-weight: 600;
	padding: 0;
	margin: 0;
}

.like-mech-rating{
	display: flex;
	align-items: center;
	padding: 0;
	margin: 0;
}

.like-mech-rating .cMkWlf{
	padding: 0;
}

.like-mech-rating span{
	margin-left: 8px;
	font-size: 8px;
	font-weight: 300;
}

.mech-main-like{
	position: absolute !important;
	display: flex;
	top: 120px;
	right: 10px;
}
.read-only-like{
	display: none;
}

.desktop-card-store-like{
	display: flex;
	position: absolute;
	bottom: 0px;
	left: 180px;
	z-index: 5;
	
}

.desktop-likee{
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	border-radius: 50px;
	padding: 10px;
}

.mech-company-photo{
	display: none;
}


@media screen and (max-width:767px) {
	.mech-like{
		width: 100px;
		padding-left: 0px;
		position: absolute;
		right: 0;
		top: 0;
	}
	.mech-saved-wrap{
		height: 150px;
	}
	.parts-image-saved{
		width: 100px;
		height: 90%;
	}
	.mech-image-saved{
		width: 100px;
		height: 100px;
	}
	.read-only-like{
		background-color: #fff;
		padding: 10px;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.desktop-card-store-like{
		display: none;
	}

	.for-desktop{
		display: none;
	}

	
}
