#my-job {
  width: 100%;
  height: 100%;
  // background: green;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
}
.inner-wrapper-my-job {
  width: 860px;
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.page-title {
  color: #000;
  font-size: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}
.card-wrapper {
  width: 100%;
  height: 100%;
  // overflow: scroll;
  // background-color: maroon;
}
.card {
  width: 100%;
  height: max-content;
  position: relative;
  padding: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #c4c4c4;
  &:last-child {
    border-bottom: 1px solid #c4c4c4;
  }
}
.image-container {
  width: 70px;
  height: 70px;
  // background-color: aquamarine;
}
.details-tex-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.title-incard {
  color: #222;
  font-size: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.sub-title-incard {
  color: #222;
  font-size: 13px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  margin-top: 8px;
  line-height: 13px;
}
.small-text-incard {
  color: #222;
  font-size: 12px;
  font-family: "Montserrat";
  font-style: normal;
  margin-top: 8px;
  font-weight: 400;
  line-height: 12px;
}
.job-type {
  border-radius: 8px;
  width: fit-content;
  padding: 5px;
  height: 14px;
  margin-top: 8px;
  font-weight: bold;
  border-radius: 20px;
  background: #d9d9d9;
}
.no-job-text {
  color: #000;
  font-size: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-top: 1rem;
}
.call-modal-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.inner-call-card {
  width: 415px;
  height: 176px;
  border-radius: 4px;
  margin-top: 3rem;
  background: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border: 0.25px solid #c4c4c4;
}
.call-title-txt {
  color: #000;
  font-size: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;

  line-height: 22px;
  letter-spacing: 0.5px;
}
.phone-txt-call {
  color: #000;
  margin-top: 1rem;
  font-size: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 1000px) {
  .inner-wrapper-my-job {
    width: 100%;
    // background-color: pink;
  }
  .title-incard {
    font-size: 12px;
  }
  .sub-title-incard {
    font-size: 11px;
  }
  .job-type {
    font-size: 12px;
  }
}
