.sort-filters-mobile{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sort-main{
    width: 90%;
    margin: 0 auto;
}
.sort-form{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.sort-form label{
    display: flex;
    flex-direction: column;
}

.sort-head{
    display: flex;
    align-items: center;
    width: 100% !important;
}

.sort-title{
    font-size: 14px ;
    margin-left: 12px;
}

.sort-form select{
    -webkit-appearance: none;
    margin: 6px 0 24px;
    padding: 11px 16px;
    border-radius: 3px;
    border: 1px solid #c4c4c490;
    color: #c4c4c4;
    background-color: #fff;
}

.mob-sort-col{
   display: none;
   
}

.search-col-tab{
    display: flex;
    width: 100%;
}

@media screen and (max-width:800px) {
    .mob-sort-col{
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 24px;
    }

    .mob-sort-tab{
        color: var(--primary1);
        font-weight: 600;
        cursor: pointer;
    }

    .search-col-tab{
        margin-top: 12px;
    }
}

