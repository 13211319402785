.switch {
	position: relative;
	display: inline-block;
	width: 25px;
	height: 15px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 12px;
	width: 12px;
	left: 3px;
	top: 1px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	/* border: 0.8px solid lightslategray; */
}

input:checked + .slider {
	/* background-color: #31aa52; */
	background-color: var(--primary1);
	/* #2196f3; */
}

input:focus + .slider {
	/* box-shadow: 0 0 1px #31aa52; */
	box-shadow: 0 0 1px var(--primary1);
	/* #2196f3; */
}

input:checked + .slider:before {
	-webkit-transform: translateX(9px);
	-ms-transform: translateX(9px);
	transform: translateX(9px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 50px;
}

.slider.round:before {
	border-radius: 50%;
}
