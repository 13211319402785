.home {
	height: 100%;
	background-color: #ededed;
	/* white; */
}

.home-item-margin {
	margin-left: 150px;
	margin-right: 150px;
	margin-top: 100px;
}

.home-box-border {
	border-radius: 8px;
	border: 1px solid lightgrey;
}

.home-box-no-border {
	border-radius: 8px;
	border: 1px solid lightgrey;
}

.home-subtitle {
	color: var(--secondary1);
	font-weight: bold;
}

.h4 {
	color: white;
}
.h6 {
	color: white;
	font-size: 10px;
}

.mb2 {
	margin-bottom: 10px;
}

.home-row-1 {
	height: 25vh;
	display: flex;
	justify-content: center;
	background-color: white;
	padding-bottom: 20px;
}

.home-row-1-container {
	width: 100%;
	max-width: 1200px;
}

.home-row-1-row-1 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 50px;
}

.home-row-1-row-1-box {
	background: white;
	height: 5vh;
	display: flex;
	align-items: center;
	border: 0.8px solid lightgrey;
	border-radius: 8px;
	font-size: 16px;
	padding: 30px;
	color: var(--secondary1);
	font-weight: bold;
}

.home-row-1-row-1-box > img {
	width: 30px;
	margin-right: 30px;
}
.home-row-1-row-1-box:nth-of-type(2) > img {
	width: 25px;
}
.home-row-1-row-1-box:nth-of-type(3) > img {
	width: 32px;
}

.home-row-2-grid-box {
	display: grid;
	grid-template-columns: 1.8fr 1fr;
	/* height: 450px; */
	/* grid-gap: 20px; */
	/* background-color: red; */
}

.home-row-2-grid-box-b1 {
	/* background: tomato; */
	border-radius: 10px;
	border: 1px solid lightgrey;
	height: 330px;
	display: grid;
	grid-template-rows: 1fr auto;
	margin-right: 30px;
}
.home-row-2-grid-box-b1-row-1 {
	width: 100%;
}
.home-row-2-grid-box-b1-row-2 {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding-left: 40px;
	padding-bottom: 15px;
	/* background-color: red;
	padding-top: 10px; */
}
.home-row-2-grid-box-b1-row-2 > div:nth-of-type(1) {
	font-weight: bold;
	margin-bottom: 10px;
	color: var(--secondary1);
}
.home-row-2-grid-box-b1-row-2 > div:nth-of-type(2) {
	font-size: 14px;
	color: var(--secondary1);
}

.home-row-2-grid-box-b2 {
	display: grid;
	grid-template-rows: 1fr 1fr;
	border-radius: 10px;
	/* border: 1px solid lightgrey; */
	grid-gap: 20px;
}

.home-row-2-grid-box-b2 > div {
	border: 1px solid lightgrey;
	border-radius: 10px;
	height: 150px;
	/* background: red; */
}

.home-row-2-grid-box-b2-col-1 {
	display: flex;
}
.home-row-2-grid-box-b2-col-1 > img {
	width: 123px;
}

.home-row-2-grid-box-b2-col-2 {
	display: flex;
}
.home-row-2-grid-box-b2-col-2 > img {
	width: 123px;

	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.home-row-2-grid-box-b3 {
	grid-column: 1/4;
	margin-top: 2rem;
}
.home-row-2-grid-box-b3-row-1 {
	background-image: url(../../../Resources/Images/cornerstone.png);
	height: 270px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	border-radius: 10px;
}
.home-row-2-grid-box-b3-row-1 > div {
	height: 60px;
	width: 95%;
	display: flex;
	align-items: center;
	background-color: white;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding-left: 20px;
	color: var(--secondary1);
}

.home-row-2-grid-box-b3-row-2 {
	height: 170px;
	display: flex;
	justify-content: center;
}
.home-row-2-grid-box-b3-row-2 > div {
	width: 95%;
	height: 100%;
	background-color: white;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
	padding: 20px;
	padding-top: 0px;
}

.four-hombres {
	border-radius: 10px;
	height: 150px;
	object-fit: cover;
	width: 100%;
}

.home-row-4 {
	margin-top: 60px;
	/* background-color: red; */
	width: 100%;
}
.home-row-4-row-1 {
	display: grid;
	grid-template-columns: 1fr 0.4fr;
	grid-gap: 20px;
}

.home-row-4-row-1-b1 {
	height: 270px;
	width: 100%;
	background-image: url(../../../Resources/Images/towtruck.jpg);
	border-radius: 8px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	padding: 20px;
	padding-bottom: 5px;
}
.home-row-4-row-1-b1 > h3 {
	font-weight: bold;
	color: white;
}

.home-row-4-row-1-b2 {
	border-radius: 8px;
	background-image: url(../../../Resources/Images/experience.jpg);
	padding: 10px;
}

.home-row-4-row-1-b3 {
	display: flex;
	justify-content: space-around;
	align-items: flex-end;
	height: 80%;
	padding: 20px;
}

.social-icon > img {
	height: 35px;
	width: 35px;
}

.home-row5 {
	justify-content: center;
	align-items: center;
	display: grid;
	place-items: center;
	margin-top: 70px;
}

.home-row5-text1 {
	font-size: 13px;
	font-weight: bold;
	margin-bottom: 5px;
}

.home-row5-text2 {
	font-size: 10px;
	font-weight: bold;
	color: var(--secondary1);
}

a[href^="http://maps.google.com/maps"]
{
	display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
	display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
	display: none;
}
.gmnoprint div {
	background: none !important;
	position: absolute;
	top: 60px;
	left: 35px;
}

.gm-bundled-control,
.gm-style-mtc,
.gm-fullscreen-control {
	transform: scale(0.6);
}

.gm-fullscreen-control {
	position: absolute !important;
	top: -15px !important;
	right: -15px !important;
}

@media screen and (max-width: 800px) {
	.home-row-1 {
		height: 250px;
	}

	.home-row-1-container {
		width: 100%;
		max-width: 100vw;
		/* height: 100%; */
	}

	.home-row-1-row-1 {
		display: flex;
		flex-direction: column;
		padding: 15px;
		grid-gap: 20px;
	}

	.home-row-1-row-1-box {
		padding-top: 20px;
		padding-bottom: 20px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.home-row-2-grid-box-b1 {
		height: 100%;
		display: grid;
		grid-template-rows: 1fr auto;
		margin-right: 0px;
		margin-bottom: 20px;
	}

	.home-row-2-grid-box {
		display: flex;
		flex-direction: column;
		padding: 20px;
		width: 100%;
	}

	.home-row-2-grid-box-b2 {
		display: flex;
		flex-direction: column;
	}

	.home-row-2-grid-box-b3 {
		width: 100%;
		margin-top: 0px;
	}

	.home-row-2-grid-box-b3-row-1 {
		background-size: contain;
		background-color: #1e1241;
		height: 150px;
		margin-bottom: 20px;
	}

	.home-row-2-grid-box-b3-row-1 > div {
		display: none;
	}

	.home-row-2-grid-box-b3-row-2 {
		width: 100%;
		display: block;
		height: 100px;
		padding: 0px;
	}

	.home-row-2-grid-box-b3-row-2 > div {
		width: 89vw;
		box-sizing: border-box;
		overflow: scroll;
		padding: 0px;
		grid-gap: 20px;
	}

	.four-hombres {
		width: 180px;
		height: 100px;
	}

	.home-subtitle {
		padding-left: 20px;
	}

	.home-row-4 {
		margin-top: 20px !important;
	}

	.home-row-4-row-1-b2 {
		margin-top: 5px;
	}

	.home-subtitle {
		text-align: center;
	}

	.home-row-4-row-1 {
		display: flex;
		flex-direction: column;
		padding-left: 20px;
		padding-right: 20px;
	}
}
