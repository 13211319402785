.download-app {
	height: 270px;
	display: grid;
	grid-template-columns: auto 1fr auto;
	border-radius: 8px;
	border: 1px solid var(--secondary3);
	background-color: white;
	margin: 2rem auto;
}

.download-app-img {
	width: 304px;
	height: 100%;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.download-app-col2 {
	display: grid;
	grid-template-rows: 1fr 1.5fr;
	padding: 20px;
	padding-top: 5px;
}

.download-app-col2-row1 {
	margin-top: 20px;
}

.download-app-text1 {
	color: var(--secondary1);
	font-size: 17px;
	/* font-weight: bold; */
	margin-bottom: 5px;
}

.download-app-text2 {
	color: var(--secondary1);
	font-size: 13px;
}

.download-app-text3 {
	color: var(--secondary1);
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 20px;
}

.download-app-text4 {
	font-size: 10px;
}

.download-app-col2-row1-row1 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
}

.download-app-input {
	border-radius: 8px;
	min-width: 120px;
}

.download-app-col3 {
	width: 200px;
}

.appstoreCol{
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	justify-content: center;
	
}

.appDownloadContainer{
	width: 250px;
	height: auto;
}

.appDownloadContainer img{
	object-fit: contain;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 800px) {
	.download-app {
		display: flex;
		flex-direction: column;
		height: fit-content;
		/* border: none; */
		align-items: center;
		width: 90%;
		margin: 10px auto;
	}
	.appstoreCol{
		align-items: center;
		flex-direction: column;
		width: 100%;
		margin: 30px auto;
		
	}
	
	.appDownloadContainer{
		width: 200px;
		height: auto;
	}


	
	.download-app-img {
		/* padding-left: 10px;
		padding-right: 10px; */
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0;
		object-fit: cover;
		height: 200px;
		width: 100%;
	}

	.download-app-col2{
		padding: 20px;
		grid-template-rows: 0.7fr 1.5fr;
	}

	.download-app-col2-row1 {
		margin-top: 0px;
	}

	.download-app-col2-row1-row1 {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.download-app-text1 {
		color: var(--secondary1);
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 5px;
	}
}
