@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
	-webkit-tap-highlight-color: transparent;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ededed;
	-webkit-text-size-adjust: 100%;

	/* font-weight: 500; */
}

@media screen and (max-width: 468px) {
	body {
		background-color: white;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.dv-star-rating,
.dv-star-rating-non-editable {
	display: flex !important;
}

:root {
	--theme: white;
	--accent1: #587fc0;
	/* --accent1: #1976d2; */
	--accent2: #fdd05d;
	--baseBg: #141d38;

	--accent_opacity: #1976d242;

	--primary1: #587fc0;
	--primary2: #fbb500;
	--primary3: #ffffff;

	--secondary1: #01195a;
	--secondary2: #738ead;
	--secondary3: #c4c4c4;
	--secondary4: #ededed;
	--secondary5: #646464;
	--secondary6: #c4c4c4;
	--inputBorder: #dbdee7;
}

@font-face {
	font-family: Montserrat;
	src: url("./Resources/Fonts/Montserrat/Montserrat-Light.ttf");
}

@font-face {
	font-family: Roboto;
	src: url("./Resources/Fonts/Roboto/RobotoMono-Medium.ttf");
}

.twolines {
	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 16px; /* fallback */
	max-height: 32px; /* fallback */
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.hideScroll::-webkit-scrollbar {
	scrollbar-color: white;
	scrollbar-arrow-color: white;
	display: none;
}
.hideScroll::-webkit-scrollbar-track {
	display: none;
}

.hideScroll-firefox {
	scrollbar-width: none;
}

.scroll-bar-type-1::-webkit-scrollbar {
	width: 5px;
}
.scroll-bar-type-1::-webkit-scrollbar-track {
	background: white;
}
.scroll-bar-type-1::-webkit-scrollbar-thumb {
	background: lightgrey;
}
.scroll-bar-type-1::-webkit-scrollbar-thumb:hover {
	/* background: #555; */
}

.scroll-bar-type-2::-webkit-scrollbar {
	width: 10px;
	background: #848687ab;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.scroll-bar-type-2::-webkit-scrollbar-track {
	/* background: rgba(0, 0, 0, 0.615); */
}
.scroll-bar-type-2::-webkit-scrollbar-thumb {
	width: 5px;
	background: #c4c4c4;
	border-radius: 5px;
}

.transparent {
	filter: alpha(opacity=0);
	-moz-opacity: 0 !important;
	opacity: 0 !important;
}

.single_chip:nth-of-type(1) {
	background: var(--accent_opacity);
	color: var(--accent1);
}

div {
	box-sizing: border-box;
}

.calendar {
	border: none;
	width: 100%;
}
.react-calendar__month-view__days__day--weekend {
	color: black;
	font-weight: bold;
}
abbr[title] {
	text-decoration: none;
}
.react-calendar__tile:active {
	background: var(--accent1) !important;
}
.react-calendar__tile--active:active {
	background: var(--accent1) !important;
	color: white !important;
}
.react-calendar__tile:disabled {
	background-color: white;
	color: rgba(16, 16, 16, 0.3);
}
.react-calendar__tile abbr {
	font-weight: bold;
}
.react-calendar__navigation button[disabled] {
	background-color: white;
}
.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label__labelText--from {
	color: black;
	font-weight: bold;
}

.bounceIn {
	animation-duration: 0.3s;
	animation-name: fadeAndScale;
	animation-timing-function: cubic-bezier(0.71, 0.55, 0.62, 1.57);
}

.slideDown {
	transition: all 0.5s ease-in-out;
	transform-origin: left top;
	transform: scaleY(0);
}

.spec_box::-webkit-scrollbar {
	display: none;
}

select:focus {
	outline: none;
}

button {
	outline: none;
	border: none;
	transition: 0.1s ease-in-out;
	background: none;
	cursor: pointer;
}

button:active {
	transform: scale(0.9);
}

input,
textarea {
	outline: none;
	box-sizing: border-box;
	border: 1px solid grey;
}

input {
	height: 35px;
	/* width: 100px; */
	border: 1px solid grey;
	border-radius: 2px;
	font-size: 13px;
	padding-left: 10px;
	padding-right: 10px;
}

.sigma::-webkit-input-placeholder {
	/* WebKit browsers */
	color: var(--secondary2);
	font-family: Montserrat;
}

div {
	box-sizing: border-box;
	scroll-behavior: smooth;
	font-family: Montserrat;
	font-size: 13px;
	/* color: var(--secondary1); */
}

img,
div {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	object-fit: cover;
}

@keyframes animateIn {
	0% {
		opacity: 0;
		transform: scale(0.6) translateY(-8px);
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeAndScale {
	from {
		opacity: 0;
		transform: scale(0.9, 0.9);
	}
	to {
		opacity: 1;
		transform: scale(1, 1);
	}
}

.change-select {
	color: grey;
}

.autolocation {
	color: #000;
	width: 100%;
	font-size: 13px;
	padding: 10px;
	border: 1px solid #dbdee7;
	box-sizing: border-box;
	border-radius: 4px;
	/* margin-top: 8px; */
	height: 45px;
}

/* instant toggle global control */

.instantService {
	/* background-color: red; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -10px;
	/* width: 100%; */
}
.instantLearn {
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	margin-top: 10px;
	cursor: default;
	color: #000;
}

.instantLearn:hover {
	color: #000;
}

.instantsub {
	color: #000;
}

.instantsub:hover {
	color: #000;
}

.learnMoreInstantBtn {
	color: var(--accent1);
	cursor: pointer;
	font-size: 11px;
	margin-top: 4px;
	margin-left: 10px;
}

.verify-notice {
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 15px;
}

.verify-notice span {
	font-weight: bold;
	margin-right: 3px;
}

.instantToggle {
	margin-top: -35px;
}

.instantToggle input[type="checkbox"] {
	height: 0;
	width: 0;
	visibility: hidden;
}

.instantToggle label {
	cursor: pointer;
	text-indent: -9999px;
	width: 24px;
	height: 13px;
	background: var(--secondary3);
	display: block;
	border-radius: 100px;
	position: relative;
}

.instantToggle label:after {
	content: "";
	position: absolute;
	top: 2px;
	left: 3px;
	width: 10px;
	height: 9px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.instantToggle input:checked + label {
	background: #31aa52;
}

.instantToggle input:checked + label:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.instantToggle label:active:after {
	width: 12px;
}

.instantCloseBtn {
	position: absolute;
	top: 0;
	right: 10px;
	cursor: pointer;
}

.dasinstant {
	width: 100%;
	height: 52px;
	border-top: 1px solid #00000030;
	border-bottom: 1px solid #00000030;
	margin: 10px 0;
}

.dashinstantspan {
	font-weight: bold;
	font-size: 14px;
	margin-left: 10px;
}

.supportTab p {
	margin: 0;
	padding: 0;
}

.savedItemBtn {
	display: flex;
	align-items: center;
	color: var(--accent1);
	font-weight: 700;
	font-size: 14px;
	margin: 25px 0 30px;
}

.savedItemBtn img {
	margin-right: 16px;
}

@media screen and (min-width: 800px) {
	.dasinstant {
		display: none;
	}
}
@media screen and (max-width: 800px) {
	.verify-notice {
		width: 90%;
		margin: 0 auto;
	}
	.take-me-to-app {
		background-color: #fff;
		border-radius: 6px;
		color: var(--primary1);
		padding: 12px 50px;
		font-weight: 600;
		font-size: 14px;
		width: fit-content;
		margin: 0 auto;
		text-decoration: none;
	}
}
