.address-banner{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #eee;
    z-index: 1;
}

.address-google-tab{
    background-color: #fff;
    width: 638px;
    border-radius: 8px;
    margin: 40px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 10;
}

.address-google-tab p{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin: 30px auto 5px;
}

.address-formm{
    display: flex;
    justify-content: center;
    /* width: 80%; */
    flex-direction: column;
    margin: 40px auto;

}

.address-formm label, .address-formm select{
    display: flex;
    flex-direction: column;
    width: 330px;
    font-size: 14px;
    margin-bottom: 24px;
    position: relative;
}

.warninng{
    color: red;
    font-weight: 300;
    font-size: 11px;
    position: absolute;
    top: 65px;
    border-radius: 5px;
    left:0px;
    /* background-color: rgb(252, 234, 234); */
}

.address-formm Select {
    padding: 10px;
    border: 1px solid #DBDEE7;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 10px;
}

.address-formm #ad-input{
    border: 1px solid #DBDEE7;
box-sizing: border-box;
border-radius: 4px;
margin-top: 8px;
width: 330px;
}

.address-submit{
    background: var(--accent1);
    border: 0.5px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    color: #fff;
    padding: 15px;
    font-size: 18px;
    font-weight: 700;
    margin-top: 29px;
}

@media screen and (max-width:800px) {
    .address-google-tab{
        background-color: #fff;
        width: 100%;
        border-radius: 0px;
        margin: 0px auto;
    }
}