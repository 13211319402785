.new_post_ad {
	display: flex;
	justify-content: center;
	margin: 0px auto;
	background-color: #ededed;
	padding-top: 50px;
}

.mob_indicator {
	width: 30% !important;
}

.mob_bar {
	/* margin-top: -50px !important; */
}

.mob_wrap {
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

.adFooter{
	display: block;
}

@media screen and (max-width: 800px) {

	.new_post_ad {
		background-color: #ffffff;
		padding-top: 0px;
	}

	.mob_bar {
		margin-top: -50px !important;
	}
	.adFooter{
		display: none;
	}
}