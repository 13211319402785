.banner{
	/* background: linear-gradient(0deg, #587FC0 45.43%, rgba(88, 127, 192, 0) 124.14%);; */
}
.spare-part-banner select {
	background: url("../../../Resources/Icons/selectarrow1.png") no-repeat right
		#fff;
	-webkit-appearance: none;
	background-position-x: 90px;
}

.drop-check {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>")
		no-repeat;
	background-size: 12px;
	background-position: calc(100% - 10px) center;
	background-position-y: 15px;
	background-repeat: no-repeat;
}

.spare-part-banner #banner-model {
	border-right: none;
}

.home-panel {
	height: 320px;
	/* width: 75%; */
	/* width: 98%; */
	/* max-width: 1200px; */
	/* width: 73%; */
	background-color: white;
	border-radius: 10px;
	/* min-width: 1020px; */
}

.home-panel-row-1 {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	height: 90px;
}

#towing > img {
	width: 30px;
}
#security > img {
	width: 25px;
}

.home-panel-row-2 {
	display: grid;
	grid-template-rows: 1fr 3fr auto;
	place-items: center;
	padding-top: 15px;
}

.sliding-chips {
	display: flex;
	width: 100%;
}

.home-panel-search-bars {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 25px;
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
}
.home-panel-search-bar-box {
	width: 100%;
	height: 40px;
	display: flex;
	border: 1px solid lightgrey;
	align-items: center;
	/* padding: 10px; */
	border-radius: 5px;
	position: relative;
}
.home-panel-search-bar-box:nth-of-type(1) > img {
	width: 13px;
	margin-right: 10px;
	margin-left: 10px;
}
.home-panel-search-bar-box:nth-of-type(2) > img {
	width: 20px;
}

.home-panel-search-bar {
	width: 100%;
	border: none;
	text-align: left;
	position: relative;
	/* font-size: 16px; */
}

.home-panel-search-btn {
	display: grid;
	height: 40px;
	width: 150px;
	place-items: center;
	padding-left: 15px;
	padding-right: 15px;
	white-space: nowrap;
	border-radius: 5px;
	background-color: var(--accent1);
	color: white;
}

.banner-sparepart-select {
	background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
		no-repeat;
	background-position: calc(95%) center !important;
	-moz-appearance: none !important;
	-webkit-appearance: none !important;
	appearance: none !important;
	padding-right: 2rem !important;
}

@media screen and (min-width: 800px) {
	.home-panel {
		width: 130%;
	}
}
.txt-area{
	position: absolute;
	bottom: 0px !important
	;display: flex;
	flex-direction: column;
	width:600px;
	left: 3rem;
	margin-bottom: 2rem;
	/* background-color: green; */

}
.title-txt{
font-family: 'Montserrat';
	font-style: normal;
	font-weight: 800;
	font-size: 30px;
	line-height: 37px;
	color: #FFFFFF;
}
.label-txt{
	font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		color: #FFFFFF;
}
@media screen and (max-width: 800px) {
	.home-panel {
		height: 100%;
		margin-bottom: 10px;

		/* height: 320px; */
	}

	.home-panel-row-1 {
		width: 100vw;
		overflow-x: scroll;
	}

	.need-help {
		padding-left: 20px;
	}

	.home-panel-search-bars {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 0px;
	}

	.home-panel-search-bar-box {
		margin: 0px;
		width: 90vw;
	}

	.home-panel-search-btn {
		width: 90%;
	}

	.home-panel {
		/* height: 100%; */
		/* background: red; */
		border-radius: 0px;
	}
}
