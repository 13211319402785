.login-wrapper {
	background-color: #fff;
	padding: 40px 0;
}

.login {
	background: white;
	/* height: 630px; */
	width: 638px;
	/* border-radius: 10px; */
	/* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); */
	align-items: center;
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.error {
	color: red;
	font-weight: 500;
	/* text-align: center; */
	margin-bottom: 10px;
	/* font-style: italic; */
}

.space {
	margin-top: 20px;
	font-size: 13px;
}

.space-2 {
	margin: 26px 0;
	font-size: 13px;
	font-family: "montserrat";
}

Link {
	text-decoration: none;
}

.form-control {
	width: 100%;
	border-color: #d6d6d6;
	margin-top: 10px;
}

.pass {
	border: none;
	width: 100% !important; 
	margin: 0;
}

.alert-danger {
	color: red;
	/* text-align: center; */
	margin-top: 5px;
	padding-bottom: 5px;
	font-weight: 400;
	font-size: 0.8rem;
}

.login-title {
	font-size: 15px;
	font-family: "montserrat";
	width: 100%;
	text-align: center;
	padding: 10px;
	font-weight: 500;
	margin-top: 24px;
	margin-bottom: 16px;
}

.reg-box {
	padding: 2rem auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.input-box {
	margin-top: 24px;
	margin-bottom: 8px;
}

.input-box label {
	font-size: 14px;
	margin-bottom: 10px;
}

.terms-and-service {
	background: #ededed;
	margin-top: 10px;
	font-size: 11px;
	line-height: 18px;
	display: flex;
	align-items: flex-start;
	border-radius: 5px;
	width: 100%;
	padding: 10px;
	color: #222222;
}
.terms-and-service > div {
	font-size: 12px;
	margin-left: 10px;
	/* color: white; */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.terms-and-service > div > span {
	color: var(--accent1);
}

.reg-box-right {
	/* background: black; */
	display: flex;
	justify-content: flex-end;
}

.social-btn {
	display: flex;
	height: 40px;
	font-size: 12px;
	font-weight: bold;
	font-family: "montserrat";
	width: 198px;
	justify-content: space-between;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	white-space: nowrap;
	border-radius: 5px;
	margin-bottom: 16px;
	border: 1px solid;
}

.login-details {
	margin-bottom: 24px;
}

.social-btn > div {
	font-size: 12px;
}
.social-btn > img {
	width: 20px;
	margin-right: 10px;
}

.signin-btn {
	display: flex;
	justify-content: center;
	margin-top: 1px;
	display: flex;
	width: 357px;
	height: 40px;
	align-items: center;
	text-align: center;
	padding: 0 auto;
	white-space: nowrap;
	border-radius: 5px;
	/* margin-bottom: 49px; */
	/* border: 1px solid; */
	background-color: #587FC0;
	color: #fff;
	font-size: 18px;
	font-weight: bolder;
	font-family: "montserrat";
	transition: 0.8s;
}

.signin-btn:hover {
	background-color: #587FC0a0;
}

.fb-type {
	background-color: #1976D2;
	color: white;
}

.or{
	display: none;
}

.gog {
	padding-right: 12px;
}

.reg-box-right > div > div {
	color: #424242;
	font-size: 10px;
	margin-top: 10px;
}

.pass_field {
	display: flex;
	justify-content: space-between;
	border: 1px solid #d6d6d6;
	border-radius: 3px;
	width: 100%;
	margin-top: 10px;
	height: 38px;
}

.signUp-info {
	text-align: center;
	margin-bottom: 3rem;
}

.signUp-info a {
	font-weight: 600;
	color: #587FC0;
	text-decoration: none;
	transition: 0.8s;
}

.signUp-info a:hover {
	text-decoration: underline;
	color: var(--primary2);
}

.mobile_title{
	display: none;
}

@media screen and (max-width: 800px) {
	.login {
		width: 90%;
		border-radius: 0px;
	}

	.login-wrapper{
		padding-top: 10px;
	}

	.mobile_title{
		display: block;
		font-size: 16px;
		font-weight: 600;
		color: #01195a;
		width: 90%;
		margin: 10px auto 15px;
	}

	.or{
		display: block;
		margin-top: 10px;
		width: 100%;
		text-align: center;
	}

	.social-btn {
		width: 100% !important;
		justify-content: center;
	}

	.fb-type{
		width: 100%;
	}

	
	.signin-btn{
		width: 100%;
		margin: 21px auto;
	}
	

	.form-control{
		width: 100%;
		margin: 0 auto;
	}

	.reg-box-left {
		width: 100%;
	}

	.reg-box-right{
		width: 100%;
	}
	.reg-box-inner{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		
	}
	
	
}
