.account-wrapper{
    box-sizing: border-box;
    background-color: #fff;
    width: 700px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px auto;
    border: 0.5px solid #c4c4c460;
    /* position: relative; */
}


.account-wrapper h3 span {
    margin-left: 2rem;
    color: #000000;
    font-weight: 600;
    font-size: 18px;
}

.account-wrapper h3 {
    border-bottom: 0.5px solid #c4c4c460;
    font-size: 18px;
    padding: 16px 0;
    margin: 0 0 2rem;
    text-align: center;

    
}

.profile-form{
    /* background-color: red; */
    width: 321px;
    margin: 0 auto;
    /* padding: 10px 180px; */
}

.account-details{
    padding: 0 2rem ;
    margin-bottom: 0.5rem;
}

.account-details > p {
    padding: 0;
    margin-bottom: 8px;
}

.account-inline-detail{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 361px;
}

.account-inline-detail .edit-btn{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500px;
    cursor: pointer;
    color: var(--primary1);
    transition: .5s;
    margin-left: 1rem;
   
}

.passwordTab{
    border: 1px solid #c4c4c480;
    border-radius: 4px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 254px;
}

.copy{
    cursor: pointer;
}

.passwordTab input{
    border: none;
    outline: none;
    width: 100%;
    padding: 0 10px;
}

.account-inline-detail .edit-btn:hover{
    color: var(--primary2);
}

.account-inline-detail input, .account-inline-detail select{
    width: 254px;
    border-radius: 4px;
    border: 1px solid #DBDEE7;
    color: #222;
    font-size: 14px;
    padding: 10px;
    /* background-color: red; */
}

.account-details:last-child{
    margin-bottom: 3rem;
}

.account-inline-detail .pw-eye{
    width: 20px;
		margin-top: 7px;
		height: 20px;
		cursor: pointer;
		padding: 0 10px 0;
        position: absolute;
        top: 10px;
        right: 15%;
        transform: translate(-50%, -50%);
}

.password-reveal .pw-display{
   padding: 3px 5px;
   text-align: center;
   cursor: pointer;
   font-size: 18px;
}

.update-pop{
    width: 100%;
    height: 100%;
    background-color: #00000080;
    position: fixed;
    top: 0;
    left: 0;
    z-index:9;
    overflow-Y: hidden;
}

.firstname{
    background-color: #fff;
    width: 391px;
    /* height: 270px; */
    border-radius: 8px;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 auto ;
}

.firstname h4{
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.firstname label{
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
}

.firstname input{
    border: 1px solid #DBDEE7;
    margin-top: 0.5rem;
    font-size: 14px;
    color: #646464;
}

.firstname select{
    border: 1px solid #DBDEE7;
    margin-top: 0.5rem;
    font-size: 14px;
    color: #646464;
    width: 180px;
    padding: 10px;
}



.firstname .btns{
    margin-bottom: 2rem;
}

.btns .save{
    background-color: var(--primary1);
    padding: 10px 25px;
    margin-right: 1rem;
    border-radius: 5px;
    color: #fff;
    /* border: 0.5px solid #000000; */
    
}
.btns .cancel{
    background-color: var(--secondary6);
    padding: 10px 25px;
    border-radius: 5px;
    font-weight: 700;
    /* border: 0.5px solid #000000; */
}

.submit-update{
    width: 100px;
    height: 100px;
}

.submit-update img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.alert-error{
    color: red;
    padding: 1rem 2rem;
    margin: -2.7rem 1rem 1rem 4rem;
}

.mail-error{
    color: red;
    padding: 1rem 2rem;
    margin: 0rem auto;
}

.profile-container{
    width: 100%;
    position:relative;
    display: flex;
    justify-content: center;
}



.user-account-image{
    background-color: #c4c4c4;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}


.user-account-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.profile-edit-pen{
    color: var(--primary1);
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: -25px;
    right: 42%;
    background-color: #ddd;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}

/* image upload begins here */

.imageUpload-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000080;
    z-index: 2;
}

.oldImage-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 4rem auto; */
    background-color: #fff;
    width: 526px;
    height: 250px ;
    /* overflow: hidden; */
    border-radius: 5px;
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.zoom-container{
    position: absolute;
    width: 600px;
    padding-left: 60px;
    padding-right: 60px;
}

.main-upload-btn{
    background-color: var(--primary1);
    border-radius: 5px;
    position: absolute;
    bottom: -100px;
    /* left: 41%; */
    padding: 10px 25px;
}

.new-upload-btn{
    background-color: var(--primary1);
    border-radius: 5px;
    position: absolute;
    bottom: -90px;
    left: 40%;
    padding: 10px 25px;
    border: 1px solid #000;
    font-weight: 700;
}

.main-upload:active{
    transform: scale(0.9);
}

.imageUpload-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12rem auto;
    background-color: #fff;
    width: 605px;
    height: 500px ;
    /* overflow: hidden; */
    border-radius: 5px;
    position: relative;
    
}

.up_img_wrapper{
    width: 527px;
}

.newProfile-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 4rem auto; */
    background-color: #fff;
    width: 527px;
    height: 300px ;
    /* overflow: hidden; */
    border-radius: 5px;
    position: absolute;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.newProfile-wrapper .oldProf{
    background-color: var(--primary1);
    margin: 2rem auto 1rem;
}

.oldProf img{
    object-position: top;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.reactEasyCrop_Container {
    border-radius: 50%;
}

.reactEasyCrop_CropArea {
    width: 250px !important;
    height: 250px !important;
    border-radius: 50%;
}

.photo-ring{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.image-list-colum{
    position: relative;
  width: 100px;
  margin-left: -1.5rem;
}

.image-listwrap{
    display: flex;
    flex-direction: row;
    margin: 0 1rem 1rem -4rem;
    /* padding: 5px; */
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
}


.image-listwrap img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.old-image{
    display: flex;
    flex-direction: row;
    margin: 0 0 0 0.2rem;
    /* padding: 5px; */
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--primary1);
}

.old-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.oldProf{
    display: flex;
    flex-direction: row;
    margin: 3rem 0 0 0.2rem;
    /* padding: 5px; */
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--primary1);
}

.oldProf img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.image-listwrap p{
    margin-right: 0.5rem;
    cursor: pointer;
}

.image-item__btun-wrapper{
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    bottom: 5px;
    right: 0px;
    color: #fff;
    font-size: 1rem;
 }

 .submit-btn2{
    background-color: var(--primary1);
    text-transform: none;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    padding: 7px 25px;
    border-radius: 5px;
    /* margin-right: 1rem; */
    font-family: 'montserrat';
    margin-top:2rem;
    color: #fff;
 }

 .cancel-main-box{
    font-size: 18px;
    position: absolute;
    top: 16px;
    right: 16px;
    font-weight: lighter;
    
 }

 .image-upload-btn{
    font-size: 1.5rem;
    color: #000;
    cursor: pointer;
    margin: 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: .3s;
}

.image-upload-btn:active{
    transform: scale(0.9);
}

.image-bin{
    position: absolute;
    bottom: -97px;
    right: -250px;
    color: #000;
    font-size: 1.5rem;
    text-align: center;
    line-height: 0.8;
}

/* over-ridding material UI slider */

.MuiTypography-overline {
    font-size: 0.75rem;
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400;
    line-height: 2.66;
    letter-spacing: 0.08333em;
    text-transform: none !important;
}

.MuiButton-label {
    font-family: 'Montserrat' !important;
    text-transform: none !important;
}

.reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    width: 200px !important;
    height: 200px !important;
}

.copy_notice{
    background-color: #fff;
    box-shadow: 1px 1px 8px #c4c4c4;
    transform: translate(-50%, -50%);
    position: fixed;
    bottom: 0;
    left: 50%;
    padding: 10px 25px;
    border-radius: 5px;
    color: var(--primary1);
    transition: .8s;
    font-weight: 500;
}

.passwordPopLabel{
    border: 1px solid #c4c4c480;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    margin-top: 10px;
    width: 100%;
}

.update-pop label{
    width: 70%;
}

.passwordPopLabel input{
    background-color: transparent;
    border: none;
    height: 35px;
    margin-top: 0;
}

/* @keyframes enter {
    from {transform:translateY(0%);}
    to {transform:translateY(50%)}
} */


@media screen and (max-width:800px) {
    .account-wrapper{
        width: 100%;
        border-radius: 8px;
        margin: 1px auto;
        border: none;
        /* position: relative; */
    }
    .imageUpload-wrapper{
        width: 375px;
    }
    .oldImage-wrapper{
        width: 375px;
    }

    .main-upload-btn {
        left: auto;
    }
    .zoom-container{
        width: 375px;
    }
    .Profile-cropContainer-1{
        width: 200px !important;
        height: 200px !important;
    }
    .firstname{
        width: 90%;
        max-width: 375px;
    }
    .account-inline-detail{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }
    .profile-form{
       
        margin: 0 auto;
    }
    .account-inline-detail .pw-eye{
        
            right: 7%;
    }
    .newProfile-wrapper{
        width: 327px;
    }
    .profile-edit-pen{
        right: 37%;
    }
}