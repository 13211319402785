.wrapper-postjob{
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  // background-color: green;
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.inner-layout{
 width: 638px;
 height: 100%;
 background-color: white;

 display: flex;
 flex-direction: column;
}

.page-title{
  color: #000;
    font-size: 18px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.5px;
}
.input-label{
  color: #222;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
}
.input-element{
  height: 40px;
  border-radius: 4px;
    border: 1px solid #DBDEE7;
    padding: 5px;
}
.input-wrap{
  width: 100%;
  height: fit-content;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.small-txt-below-location{
  color: #222;
    font-size: 11px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-top: 5px;
}
.text-area{
  height: 119px;
  border-radius: 4px;
    border: 1px solid #DBDEE7;
    padding: 1rem;
}
.radio-label{
  color: #222;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 5px;
}

.radio-input{
width: 20px;
margin: 0px;
  height: 20px;
}
.all-radio-wrapper{
  width: 100%;
  // background-color: pink;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  justify-content: flex-start;
  
}
#company_logo{
width: 90px;
  height: 83px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: pink;
  border-radius: 5px;
    border: 1px solid #C4C4C4;
}
.small-photo-span-txt{
  color: #222;
    font-size: 9px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    margin-left: 4px;
}
.how-people-apply-txt{
  color: #000;
    font-size: 18px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;
}
.how-people-apply-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}
.how-to-row-wrapper{
  width: 100%; display:flex; align-items: center;
  flex-direction: row;
}
.row-item-how-to{
  &:first-child{
    width: 193px;
  }
  &:last-child{
    flex-grow: 1;
    margin-left: 1rem;
    margin-top:22px;
  }
}
.next-button{
  
    width: 416px;
}
.places-dropdown{
    max-height:200px;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    z-index: 1000;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: scroll;
    position: absolute;
    
    padding: 0px 3px 3px 3px;
    
}
.places-button{
  padding: 10px;
  background: #f1f1f1;
  margin-top: 3px;
  margin-bottom: "3";

  &:hover{
      background-color: #5880c0;
      color: white;
  }
}

@media screen and (max-width:1000px) {
  .inner-layout {
    width: 100%;
    padding: 0rem 1rem 0rem 1rem;
  }
    .next-button {
      width: 100%;
    }
        .how-to-row-wrapper{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
                .row-item-how-to {
                  &:first-child {
                    width: 100%;
                    margin-top: 1rem;
                  }
        
                  &:last-child {
                    flex-grow: 1;
                    margin-left: 0rem;
                    width: 100%;
                    margin-top: 1rem;
                  }
                }
}