.cat-wrapper{
    background-color: #fff;
    width: 860px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin: 40px auto 0;
    border-radius: 7px;
    padding: 24px auto;
}

.cat-wrapper p{
    font-size: 14px;
    color: #222222;
    margin: 10px 0;
}

.cat-wrapper .title-section p{
    margin: 20px auto 40px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    
}

.cat-wrapper section {
    display: flex;
    justify-content: center;
    margin-top: 0;
    padding-top: 0;
}

.cat-wrapper .category{
    width: 334px;
}

.cat-wrapper section select {
    width: 304px;
    height: 32px;
    border: 1px solid #DBDEE7;
    outline: none;
    padding: 1px 10px;
    border-radius: 4px;
    color: #8a8a8a;
    font-size: 14px;
}

.cont-btn{
    font-size: 18px;
    border-radius: 4px;
    background-color: var(--primary2);
    border: 1px solid #000;
    font-weight: 700;
    padding: 10px 45px;
    width: 209px;
    margin: 30px auto 30px;
    display: flex;
    justify-content: center;
}

#cat-description{
    font-size: 10px;
    line-height: 17px;
}
