.floating-btn {
	color: #fff;
	background-color: var(--primary1);
	border-radius: 100%;
	cursor: pointer;
	text-align: center;
	margin: 0;
	transition: 1s;
	text-align: center;
	position: fixed;
	bottom: 35px;
	right: 30px;
	animation: bounce 1.8s linear 2s infinite alternate backwards;
	z-index: 40;
	-webkit-box-shadow: 0px 7px 10px -2px rgba(0, 0, 0, 0.5);
	border-radius: 80px;
	width: 150px;
	height: 32px;
	box-shadow: 0px 1px 7px -2px rgba(0, 0, 0, 0.7);
	display: grid;
	place-items: center;
	font-size: 10px;
	font-weight: bold;

	display: flex;
	justify-content: center;
}

.floating-btn > img {
	width: 20px;
	margin-right: 12px;
}

@keyframes bounce {
	0% {
		transform: translateY(7px);
	}

	50% {
		transform: translateY(0px);
	}

	100% {
		transform: translateY(7px);
	}
}

.tooltip {
	position: fixed;
	bottom: 70px;
	right: 50px;
	z-index: 9999;
	padding: 1rem;
	background-color: #fff;
	color: #fbb500;
	font-size: 1rem;
	font-weight: 500;
	border-radius: 25px;
	border-bottom-right-radius: 0;
	visibility: hidden;
	width: 150px;
}

.floating-btn:hover .tooltip {
	visibility: visible;
}

@media screen and (max-width: 800px) {
	.floating-btn {
		width: 100px;
		padding: 5px 5px;
		display: none;
	}
}
