.NewPartsDealerStepsForm-wrapper {
  width: 100%;
  height: 100%;
  // background: red;
  display: flex;
  justify-content: center;
}
.NewPartsDealerStepsForm-inner {
  width: 1180px;
  height: fit-content;
  // background: green;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.form-wrap-part-dealer {
  border-radius: 8px;
  width: 100%;
  height: fit-content;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  padding-bottom: 2rem;
}
.inner-shadow-header {
  width: 100%;
  height: 54px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  span {
    color: #000;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
.form-body-part-dealer {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.part-dealer-form {
  width: 650px;
  height: fit-content;
  // background: pink;
  margin-top: 2rem;
}
.title-label {
  color: #fbb500;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.divider-spar-part-dealer {
  width: 100%;
  height: 0.5px;
  background: #fbb500;
}
.input-label-spare-part-dealer {
  color: #222;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.input-spar-part-dealer {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #dbdee7;
  padding: 5px;
  flex-shrink: 0;
  color: #dbdee7;

  font-family: "Montserrat";
  // font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.text-area-spar-part {
  font-family: "Montserrat";
  // font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #dbdee7;
}
.instruction-note {
  color: #646464;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 150% */
  margin-top: 1rem;
}
.conf-check-row-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0rem 1rem 0rem;
  gap: 5px;
}

.color-yellow-txt {
  color: #fbb500;
}
.label-check-box {
  color: #222;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
@media screen and (max-width: 1000px) {
  .NewPartsDealerStepsForm-inner {
    width: 90%;
  }
  .label-check-box {
    font-size: smaller;
  }
  .part-dealer-form {
    width: 100%;
  }
  .form-wrap-part-dealer {
    box-shadow: none;
  }
  .inner-shadow-header {
    box-shadow: none;
  }
  .part-dealer-form {
    margin-top: 1rem;
    background: none;
  }
}
