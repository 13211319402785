.dashboard {
	display: flex;
	justify-content: center;
	padding-top: 40px;
	background-color: #ededed;
	min-height: 100vh;
}

.dashboard .user-panel {
	/* background-color: #fff; */
	border-radius: 6px;
	min-width: 192px;
	/* height: 420px; */
	padding: 0rem 0rem;
	z-index: 1;
}

.username {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.username .dash-joined {
	margin-top: -10px;
	background-color: #dbdee7;
	border-radius: 6px;
	padding: 3px 10px;
	color: #646464;
}

.username p {
	margin-right: 0.3rem;
	text-transform: capitalize;
}

.username .username-icon-edit {
	cursor: pointer;
	color: var(--primary1);
}

.user-panel .panel-top {
	height: 158px;
	/* border: 0.5px solid #dedddd; */
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	margin-bottom: 1rem;
	background-color: #fff;
	border-radius: 6px;
}

.edit-box-popup {
	background-color: #fff;
	box-shadow: 0px 0px 8px #c4c4c480;
	padding: 0.5rem;
	position: absolute;
	top: 130%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 150px;
	z-index: 9;
}

.edit-box-popup p {
	cursor: pointer;
	transition: 0.5s;
	color: var(--primary1);
}

.edit-box-bg {
	/* background-color: red; */
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.edit-box-popup p a {
	text-decoration: none;
	color: var(--primary1);
}

.edit-box-popup p a:hover {
	text-decoration: none;
	color: var(--primary2);
}

.edit-box-popup p:hover {
	color: var(--primary2);
}

.panel-top p {
	font-size: 13px;
	font-weight: 500;
	text-transform: capitalize;
	display: flex;
	align-items: center;
}

.user-panel .user-image {
	/* width: 50px;
    height: 50px; */
	border-radius: 50%;
	overflow: hidden;
}

.user-panel .user-image img {
	object-fit: cover;
	object-position: top;
	width: 100%;
	height: 100%;
}

.user-panel .panel-list ul {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	position: relative;
	z-index: 1;
}

.user-panel .panel-list ul li {
	/* margin-top: 0.5rem; */
	list-style: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 1rem 0;
	cursor: pointer;
	/* font-weight: 500; */
	transition: 0.5s;
	/* color: red !important; */
}

/* .user-panel.panel-list ul li:hover{
    color: green !important;
} */

#list-item:hover {
	color: var(--primary2) !important;
}

.service-dash {
	background-color: #fff;
	border-radius: 6px;
	padding: 0.5rem 0 1rem;
	/* border: 0.5px solid #dedddd; */
}

.service-dash li {
	margin: 0.5rem 0;
}

.service-dash li:first-child {
	border-bottom: 0.5px solid #dedddd;
}

.edit-sub {
	/* margin: 0px 0rem 10px 10px; */
	text-decoration: none;
	color: #000;
}

.edit-sub:hover {
	color: var(--primary2);
}

.btn-dash-col {
	background-color: #fff;
	padding: 0.5rem;
	border-radius: 6px;
	/* border: 0.5px solid #dedddd; */
}

.ad-btn-dash {
	color: #fff;
	background-color: var(--primary1);
	width: 100px;
	text-align: center;
	padding: 10px 15px;
	padding-left: 30px;
	border-radius: 5px;
	cursor: pointer;
	transition: 0.8s;
	margin: 1.2rem auto;
	font-weight: 700;
	font-size: 16px;
}

.ad-btn-dash:hover {
	background-color: var(--primary2);
}

/* .panel-list ul li:active{
    background-color: var(--primary1);
} */

.list-icon img {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.dashboard .user-details {
	background-color: #fff;
	border-radius: 6px;
	width: 920px;
	min-height: 85vh;
	height: 100%;
	margin-left: 30px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
}
.dashboard .dealer-details {
	background-color: #fff;
	border-radius: 6px;
	width: 60%;
	/* height: 379px; */
	margin-left: 30px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem auto;
}

#search-pr-col {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 578px;
	margin: 0 auto;
}

.search-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #c4c4c4;
	width: 94%;
	margin-bottom: 1rem;
	border-radius: 3px;
}

.search-wrapper input {
	border: none;
	width: 100%;
	font-size: 14px;
	color: #646464;
	overflow: hidden;
}

.search-wrapper input::placeholder {
	color: #c4c4c4;
}

.search-wrapper .dashboard-search-icon {
	width: 15px;
	margin-left: 0.7rem;
}
.dashboard-filter-icon {
	width: 20px;
	margin-bottom: 10px;
	color: #c4c4c4;
	font-size: 19px;
}

.dashboard-filter-icon p {
	margin: 0;
	color: #c4c4c4;
	font-size: 10px;
}

.dashboard-search-icon img,
.dashboard-filter-icon img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.no-ad {
	width: 300px;
}

.user-details .details-top {
	margin-top: 0px;
	border-bottom: 1px solid #dbdee7;
	font-size: 15px;
	font-weight: 500;
	width: 100%;
	height: 66px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}
.user-details .detailss-top {
	margin-top: 0px;
	border-bottom: 1px solid #dbdee7;
	font-size: 15px;
	font-weight: 500;
	width: 100%;
	height: 66px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

.details-top p {
	text-align: center;
	margin-bottom: 24px;
}

.user-details .details-icon {
	height: 125px;
	width: 200px;
	/* border: 0.5px solid #1976d2; */
	margin-bottom: 30px;
}

.details-icon img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* ad display */

.ad-col-wrapper article .product-container {
	border-radius: 6px;
	border: 1px solid #c4c4c4;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0, 15);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	width: 580px;
	height: 150px;
	margin-bottom: 24px;
	text-align: left;
	overflow: hidden;
	position: relative;
	overflow: hidden;
}

.product-container .pr-item {
	margin: 1rem 0;
}
.product-container .pr-name {
	font-weight: 500;
	font-size: 14px;
}

#test {
	color: pink;
}

#main-pr-title:hover {
	color: var(--primary2);
}

#adlink-col {
	/* color: var(--primary1); */
	text-decoration: none;
	color: var(--primary1);
	font-size: 16px;
}

.product-container .pr-price {
	color: var(--primary1);
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 2rem;
}

.product-container .pr-numb {
	font-size: 10px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.603);
}

.product-container .pr-date {
	font-size: 11px;
	font-weight: 500;
	color: #646464 !important;
}

.ad-container {
	width: 94%;
}

.ad-container .product-container .prd-image {
	width: 180px;
	height: 150px;
	overflow: hidden;
	margin-right: 1.5rem;
	background-color: #fff;
	position: relative;
}

.ad-container .product-container .prd-image img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* camera icon */

#pr-image-numb {
	/* width: 20px; */
	color: #fff;
	font-size: 14px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	left: 5px;
	display: flex;
	align-items: center;
	background-color: #000;
	padding-left: 2px;
}

#cam-numb {
	font-size: 12px;
	font-weight: 500;
	color: #fff;
	/* margin-left: 3px; */
	padding: 2px;
	padding-top: 3px;
	padding-left: 7px;
	background-color: #000;
}

#pr-image-numb img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* views section */

.views {
	display: flex;
	align-items: flex-end;
	/* width: 100%; */
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.views .norm-views {
	font-size: 10px;
	margin-right: 1rem;
	background-color: #eef2f4;
	color: #000;
	padding: 8px 10px;
	border-radius: 3px;
	font-weight: 500;
}
.views .phone-views {
	font-size: 10px;
	background-color: #eef2f4;
	padding: 8px 10px;
	border-radius: 3px;
}

/* edit panel */

.edit-panel {
	position: absolute;
	top: 5px;
	right: 10px;
	background-color: #fff;
	box-shadow: 0px 5px 4px #c4c4c480;
	z-index: 6;
}

.edit-panel ul {
	padding: 0 10px;
	margin: 0;
}

.close-edit {
	position: absolute;
	top: -5px;
	right: 5px;
	padding: 1px 4px;
	font-size: 10px;
	font-weight: 600;
	color: #c4c4c4;
	cursor: pointer;
	box-shadow: 0px 1px 3px #c4c4c480;
}

.edit-panel ul li {
	margin-bottom: 0px;
	list-style: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* padding-right: 1rem; */
	cursor: pointer;
	color: var(--primary1);
	font-size: 12px;
}

.edit-panel ul .renew,
.edit-panel ul #renew {
	color: #c4c4c4;
	cursor: not-allowed;
}

.editPanelBg {
	position: fixed;
	top: 0;
	left: 0;
	background-color: transparent;
	width: 100%;
	height: 100%;
	z-index: 4;
}

.edit-panel ul li p {
	margin: 8px 5px;
}

.edit-panel .edit-icon {
	margin-right: 2px;
	margin-top: 3px;
	color: var(--primary1);
}

.edit-menu-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 2;
	color: var(--primary1);
	cursor: pointer;
}

/* sub menu left panel items */
/* .sub-menu-item{
    margin-left: 20px;
} */

.sub-menu-item:hover {
	color: var(--primary2);
}

.drop-btn {
	display: flex;
	justify-content: space-between;
	width: 70%;
}

.drop-sub-icon {
	font-size: 1rem;
	font-weight: 500;
	margin-top: 13px;
}

/* review box */
.review-star {
	position: absolute;
	top: 40px;
	right: 11px;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 6px;
	height: 60px;
	width: 170px;
	cursor: pointer;
}

.review-star .rating-title {
	font-weight: 500;
	font-size: 10px;
	margin: 0;
	padding: 8px 20px 0;
	text-align: left;
}
.rating-title_active_ads{
	font-weight: 500;
	font-size: 10px;
	margin: 0;
	padding: 8px 20px 0;
	text-align: left;
}

.rating-title span {
	background-color: #cf2d48;
	padding: 0.2rem 0.4rem;
	border-radius: 50%;
	color: #fff;
	font-size: 10px;
	font-weight: 500;
	margin-left: 5px;
}

.total-rateing-col {
	display: flex;
	align-items: center;
	padding: 10px 20px;
}

.total-rateing-col p {
	margin: 2px 0.4rem 2px 0;
	font-size: 14px;
	color: #01195a;
	font-weight: 500;
}

.active-col-ads{
	/* width: 94%; */
}
.mobile-ad-navigation{
	display: none;
}

.dashboard-mobile-nav{
	display: none;
}

.dashNavSubMenu{
	display: none;
}

.dealer_dash{
	max-width: 1180px;
	margin: 0 auto; 
}


@media  screen and (max-width:800px) {
	.user-panel{
		display: none;
	}
	.dashboard .user-details{
		width: 100%;
		margin-left: 0;
	}
	.dashboard .dealer-details{
		width: 100%;
		margin-left: 0;
		padding-top: rem;
	}
	.dashboard {
		margin-top: 0px;
		width: 100%;
		overflow-x: hidden;
		margin: 0 auto;
		padding-top: 0px;
	}
	.ad-col-wrapper article .product-container {
		width: 100%;
		align-items: flex-start;
		height: 120px;
	}

	.dealer_prod{
		height: 88px !important;
	}
	.ad-container .product-container .prd-image {
		width: 90px;
		height: 82px;
		border-radius: 5px;
		overflow: hidden;
		margin: 2px 0.7rem 0 2px;
		/* border: 0.5px solid #c4c4c4; */
	}
	.product-container .pr-name, .product-container .pr-price{
		font-size: 11px;
	}
	.product-container .pr-numb{
		font-size: 8px;
	}
	.product-container .pr-item {
		margin: 0.5rem 0;
	}
	.product-container .pr-date {
		font-size: 8px;
	}
	#pr-image-numb{
		width: 25px;
		font-size: 8px;
	}
	.edit-menu-icon{
		display: none;
	}
	.search-wrapper{
		width: 100%;
	}
	#search-pr-col{
		width: 100%;
	}
	.views{
		width: 114px;
		bottom: 40px;
	}
	.views .norm-views {
		font-size: 7px;
		padding: 4px ;
	}
	.views .phone-views {
		font-size: 7px;
		padding: 4px 2px;
	}
	.review-star {
		width: 114px;
		height: 37px;
		top:20px
	}
	.review-star .rating-title{
		font-size: 7px;
	}
	.rating-title_active_ads{
		font-weight: 500;
		font-size: 9px;
		margin: 0;
		padding: 8px 10px 0;
		text-align: left;
	}
	.total-rateing-col {
		font-size: 7px;
		padding: 0 20px;
	}
	.total-rateing-col p{
		font-size: 7px;
	}
	.star-ratt{
		font-size: 7px;
	}
	.rating-title{
		width: 100px;
	}
	.rating-title span {
		font-size: 7px;
		padding: 2px 4px;
	}
	.active-col-ads{
		width: 100%;
	}
	.mobile-ad-navigation{
		position: absolute;
		bottom: 0px;
		left: 0;
		/* background-color: red; */
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-top: 0.5px solid #c4c4c4;
		margin: 0px auto;
		height: 30px;
	}
	.mobile-ad-navigation .mob-edit-div{
		display: flex;
		align-items: center;
	}
	.mobile-ad-navigation .mob-delete-div{
		display: flex;
		align-items: center;
	}
	.mobile-ad-navigation p{
		margin: 5px 10px;
		font-size: 11px;
		cursor: pointer;
	}
	.mobile-ad-navigation .mob-edit-div p{
		margin: 3px 10px;
		color: var(--primary1);
		/* border: 0.4px solid var(--primary1); */
		border-radius: 4px;
		padding: 1px 3px;
		box-sizing: border-box;
	}
	/* .mob-edit-div p:first-child{
		border-right: 0.5px solid #c4c4c4;
		padding-right: 1rem;
	} */
	.mobile-ad-navigation .mob-delete-div p{
		margin: 3px 10px;
		/* color: var(--primary2); */
	}
	.mob-delete-div #dectivate-mob{
		/* border: 0.4px solid var(--primary2); */
		border-radius: 4px;
		padding: 1px 3px;
		box-sizing: border-box;
	}
	.mob-delete-div #renew-mob{
		border: 0.4px solid red;
		border-radius: 4px;
		padding: 1px 3px;
		box-sizing: border-box;
		color: red;
	}

	#cam-numb{
		font-size: 8px;
	}
	/* mobile nav cards begins here */
	.dashboard-mobile-nav{
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin: 0rem auto 1.3rem;
		border-bottom: 0.5px solid #c4c4c480;
		padding-bottom: 1.5rem;
	}
	.dashboardNavCards{
		width: 104px;
		height: 60px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		border-radius: 7px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		flex-direction: column;
		text-align: center;

	}
	.dashNavSubMenu{
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 0rem auto 0.5rem;
		border-bottom: 0.5px solid #c4c4c480;
		padding-bottom: 1rem;
	}
	.dashNavSubMenu li{
		list-style: none;
		cursor: pointer;
	}

	.active-col-ads{
		width: 100%;
	}
	.ad-container {
		width: 100%;
	}

	.dealer-panel-main{
		display: flex;
		flex-direction: column;
	}

	.dealer_dash{
		flex-direction: column;
		
		
	}



	.mob_shop_name{
		display: block;
		margin-top: 0;
		font-size: 17px;
		color: #01195A;
		font-weight: 600;


	}
	.dashboard-filter-icon {
		display: none;
	}
}
